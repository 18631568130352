import React, {useEffect} from 'react';
import { Helmet } from "react-helmet";
import OrganiserApp from '../../img/organiser-app.png';
import Badge from '../../img/badge.png';
import Customer from '../../img/customer.png';
import Product from '../../img/product.png';
import MobileApp from '../../img/MobileApp.png';
import OnlineTicketing from '../../img/online-ticketing.png'
import SoftwareWork from '../../img/softwareWork.png'
import AdminPannelImg from '../../img/AdminPannelImg.png'
import { Container } from 'react-bootstrap';
import { Row, Col, Carousel } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useSpring, animated } from 'react-spring';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { WebImage } from '../Image/WebImage';
import "./About.scss";

export const About = () => {
    const yearsofexperience = useSpring({ val: 10, from: { val: 0 } });
    const client = useSpring({ val: 1000, from: { val: 0 } });
    const products = useSpring({ val: 150, from: { val: 0 } });
    useEffect(() => {
        window.scrollTo({ top: 0, behavior: "smooth" });
    }, [])

    document.title = "About TicketingSoftwares Online Ticketing Software System";
    document.querySelector('meta[name="description"]').setAttribute(
        "content",
        "Know more about TicketingSoftwares online ticketing system software and start your own event platform like eventbrite."
    );
    
    return (
        <>
            <Container>
            <div className="about-hero inner-hero-phone">
                <h1 className="vertical-center">About Us</h1>
            </div>  

            <Row className="margin-top-80 align-items-center paragraph-wrpr-row">
            <Col xs={12} md={5} className="order-md-2 order-sm-1">
                    <div className="right-img">
                        <WebImage src={OrganiserApp}/>      
                    </div>
                    </Col>
            <Col xs={12} md={7} className="order-md-1 order-sm-2">
                    <div>
                        <h3>
                        An Introduction To Our Online Ticketing System Software
                        </h3>
                        <p>
                        With a dream to cater to the event industry with highly-technical and advanced resources, 
                        we come to you with an online ticketing system software. As a multi-faceted event management 
                        platform, we aim to have visitors and users interested in posting and creating buzz about 
                        upcoming events and selling those tickets.
                        </p>
                        <div>
                        <Link className="phone-center orange-btn mt-2" to="/contact">Contact Today
                        
                        <span className="tri-btn">
                        <FontAwesomeIcon icon={['fas', 'arrow-right']} size="lg"/>
                        </span>
                        
                        </Link>
                        </div>
                    </div>
                    </Col>
            </Row>

            <Row className="padding-y-40 align-items-center paragraph-wrpr-row">
                    <Col xs={12} md={5}>
                    <div className="left-img">
                    <WebImage src={OnlineTicketing}/>
                    </div>
                    </Col>

                    <Col xs={12} md={7} >
                    <div>
                        <h3>
                        What's Unique About Our Ticketing Software?
                        </h3>
                        <p>
                        You are mistaken if you think we are just a platform where people post about 
                        events. Exploring our software's core features and elements will make you realize 
                        that there is a lot more to it- it has every essential element you need. 
                        These have been curated after profoundly studying the nature of event organizers, 
                        audiences, and current event industry trends.
                        </p>
                    </div>
                    </Col>

                </Row>

                <Row className="padding-y-40 align-items-center paragraph-wrpr-row">
                <Col xs={12} md={5} className="order-md-2 order-sm-1">
                    <div className="right-img">
                        <WebImage src={SoftwareWork}/>      
                    </div>
                    </Col>
            <Col xs={12} md={7} className="order-md-1 order-sm-2">
                    <div>
                        <h3>
                        How Does Our Ticketing Software Work?
                        </h3>
                        <p>
                        Despite having numerous features, Ticketing Software is simple, so even a layperson can understand and use it without difficulty. This software has two main parts: the front end and the admin end.
                        </p>
                        <div>
                        <Link className="phone-center orange-btn" to="/contact">Buy Now
                        
                        <span className="tri-btn">
                        <FontAwesomeIcon icon={['fas', 'arrow-right']} size="lg"/>
                        </span>
                        
                        </Link>
                        </div>
                    </div>
                    </Col>
                    
            </Row>

            <Row className="padding-y-40 align-items-center paragraph-wrpr-row">
                    <Col xs={12} md={5}>
                    <div className="left-img">
                    <WebImage src={OnlineTicketing}/>
                    </div>
                    </Col>

                    <Col xs={12} md={7}>
                    <div>
                        <h3>
                        Front End
                        </h3>
                        <p>
                        The front end has two categories: the first is the publisher or the event poster, those who publish the event, and the second one is the buyer and the ones who want to purchase the event tickets.
                        </p>
                    </div>
                    </Col>

                    <Col md={12} className="top-space">
                        <div className="li-wrapper">
                        <p>
                            <h4>Event organizer</h4>
                            This is the client who is logged in to post the details of an event. Before posting, though, they have to go through the registration procedure. To do so, they must fulfill their requirements by filling in the authenticated basic information.
                        </p>
                        </div>
                    </Col>

                    <Col md={12} className="top-space">
                        <div className="li-wrapper">
                        <p>
                            <h4>Flexibility to Describe the Event</h4>
                            Organizers have the flexibility to explain their event any way they please with the help of our features. We allow organizers to describe their event through Video, Audio, and Images. We also have social media connections that they can use to create a contact list and send a post to a community. You can get a glimpse of these features in the demo version.

                        </p>
                        </div>
                    </Col>

                    <Col md={12} className="top-space">
                        <div className="li-wrapper">
                        <p>
                            <h4>Ticket Buyer</h4>
                            Buyers have the flexibility of searching for tickets they want to buy or offering a wish list that will be used to display events. Overall, it is a very simple and secure way to purchase tickets.
                        </p>
                        </div>
                    </Col>
                </Row>

                <Row className="padding-y-80 align-items-center paragraph-wrpr-row">
                <Col xs={12} md={5} className="order-md-2 order-sm-1">
                    <div className="right-img">
                        <WebImage src={AdminPannelImg}/>      
                    </div>
                    </Col>
            <Col xs={12} md={7} className="order-md-1 order-sm-2">
                    <div>
                        <h3>
                        Ticketing Software Admin Panel
                        </h3>
                        <p>
                        The Admin panel is designed for the client who owns the site and is very transparent. The admin panel allows the owner to access any information they want. With just a few clicks, they can access all sorts of information on their event and buyers. You can get a glimpse of these features in the demo version.                     </p>
                        <div>
                        <Link className="phone-center orange-btn" to="/contact">Contact Today

                        <span className="tri-btn">
                        <FontAwesomeIcon icon={['fas', 'arrow-right']} size="lg"/>
                        </span>
                        
                        </Link>
                        </div>
                    </div>
                    </Col>
                    
            </Row>

            <Row className="padding-y-40 align-items-center paragraph-wrpr-row">
                    <Col xs={12} md={5}>
                    <div className="left-img">
                    <WebImage src={OnlineTicketing}/>
                    </div>
                    </Col>

                    <Col xs={12} md={7}>
                    <div>
                        <h3>
                        Online Ticketing System Software Security
                        </h3>
                        <p>
                        Both our panels are highly secured with the integration of top-level technologies. Although the admin panel holds all the rights to information, they cannot make any change in any post, ensuring the post's security. On the other hand, we have a tested filtration system that restricts all spam posts and keeps them away from the main system.
                        </p>
                    </div>
                    </Col>

                </Row>

            </Container>

            <section className="light-bg margin-y">
                <Container>
                    <Row className="anim-card-row">
                        <Col md={4} className="py-3">
                        <div className="anim-card">
                        <div className="digit-caption">
                        <WebImage src={Badge}/>
                        <animated.div className="digits">
                            {yearsofexperience.val.interpolate(val => Math.floor(val))} 
                        </animated.div>
                        <div className="caption">Years Of Experience</div>
                        </div>
                        </div>
                        </Col>

                        <Col md={4} className="py-3">
                        <div className="anim-card">
                        <div className="digit-caption">
                        <WebImage src={Customer}/>
                        <animated.div className="digits">
                            {client.val.interpolate(val => Math.floor(val))}
                            </animated.div>
                            <div className="caption">Happy Client</div>
                        </div>
                        </div>
                        </Col>

                        <Col md={4} className="py-3">
                        <div className="anim-card">
                        <div className="digit-caption">
                        <WebImage src={Product}/>
                        <animated.div className="digits">
                            {products.val.interpolate(val => Math.floor(val))}
                        </animated.div>
                        <div className="caption">Products</div>
                        </div>
                        </div>
                        </Col>
                    </Row>
                </Container>
            </section>

            <Container>
            <Row className="padding-y-80 align-items-center paragraph-wrpr-row abt-demo-phone">
            <Col xs={12} md={5} className="order-md-2 order-sm-1">
                    <div className="right-img">
                        <WebImage src={MobileApp}/>      
                    </div>
                    </Col>
            <Col xs={12} md={7} className="order-md-1 order-sm-2">
                    <div>
                    <h2>
                        <span className="font-light">You Can Take A Look</span>  
                        <span className="orange-text"> Demo</span>
                        </h2>
                        <p>
                        If you want a software platform that provides resourceful advantages to both the 
                        clients and the event organizer, we can help. Our software platform also provides 
                        a simple gateway for purchasers who want to buy tickets for any particular event 
                        posted on the software or site. We have designed our software keeping you in mind. 
                        We incorporated all the general and crucial aspects so you can easily test all the 
                        software features by getting the demo version.
                        </p>
                        <div>
                        <Link className="phone-center orange-btn" to="/demo">See Demo
                        
                        <span className="tri-btn">
                        <FontAwesomeIcon icon={['fas', 'arrow-right']} size="lg"/>
                        </span>
                        
                        </Link>
                        </div>
                    </div>
                    </Col>
            </Row>

            
                <section className="padding-top-40 bottom-margin-80">
                <div className="center-heading">
                    <h2>
                    <span className="font-light">Event Ticketing Software</span> 
                    <span className="orange-text"> Features</span>
                    </h2>
                    </div>
                    <Row className="gx-5 top-space">
                    <Col md={6} className="abt-feature-cardone">
                        <div className="feature-wrapper">
                        <div className="feature-content">
                        <div className="image-wrapper01"></div>
                            <h4>
                            Front-end Feature
                            </h4>
                            <div>
                            The front end has two categories- the first is the publisher or the event poster, the ones who are the ones who publish the event, and the second one is the buyer and the ones who want to purchase the tickets for the events.
                            </div>
                        </div>
                        <div className="feature-btn-wapper">
                            
                            <Link className="feature-btn" to="/front-end-features">
                            <FontAwesomeIcon icon={['fas', 'caret-right']}/>
                            </Link>
                            
                        </div>   
                        </div>

                        
                    </Col>

                    <Col md={6}>
                        <div className="feature-wrapper">
                        <div className="feature-content">
                        <div className="image-wrapper02"></div>
                            <h4>
                            Admin Feature
                            </h4>
                            <div>
                            The Admin panel is designed for the client who owns the site, and is very transparent. The admin panel allows the owner to access any information they want.
                            </div>
                        </div>
                        <div className="feature-btn-wapper">
                            
                            <Link className="feature-btn" to="/admin-features">
                            <FontAwesomeIcon icon={['fas', 'caret-right']}/>
                            </Link>
                            
                        </div>  
                        </div>
                    </Col>
                    </Row>
                </section>
            
            </Container>
        </>   
    )
}
