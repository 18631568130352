import React, { Suspense } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import { Header } from "../componants/Header/Header";
import { Footer } from "../componants/Footer/Footer";
import { routes } from "../routes/routes";

function Layout() {
  let mainRoutes = (
    <>
      <Switch>
        {routes.map((route, i) => (
          <Route path={route.path} key={i} exact render={(props) => <route.component {...props} routes={route.component} />} />
        ))}
      </Switch>
    </>
  );
  return (
    <>
      <Router basename={"/"}>
        <div>
          <Header />
          <Suspense fallback={"Loading..."}>{mainRoutes}</Suspense>
          <Footer />
        </div>
      </Router>
    </>
  );
}
export default Layout;
