import ReviewImage1 from '../../img/review-img-1.png'
import ReviewImage2 from '../../img/review-img-2.png'
import ReviewImage3 from '../../img/review-img-3.png'
import ReviewImage4 from '../../img/review-img-4.png'
import ReviewImage5 from '../../img/review-img-5.png'


export const sampleDataList = [
    {
        image: ReviewImage1,
        name: "John Smith1",
        role: "Organiser at Lotus Event",
        testimonyMsg: "1Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatiset quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas"
    },
    {
        image: ReviewImage2,
        name: "John Smith2",
        role: "Organiser at Lotus Event",
        testimonyMsg: "2Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatiset quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas"
    },
    {
        image: ReviewImage3,
        name: "John Smith",
        role: "Organiser at Lotus Event",
        testimonyMsg: "3Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatiset quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas"
    },
    {
        image: ReviewImage4,
        name: "John Smith",
        role: "Organiser at Lotus Event",
        testimonyMsg: "4Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatiset quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas"
    },
    {
        image: ReviewImage5,
        name: "John Smith",
        role: "Organiser at Lotus Event",
        testimonyMsg: "5Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatiset quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas"
    },
    {
        image: ReviewImage1,
        name: "John Smith6",
        role: "Organiser at Lotus Event",
        testimonyMsg: "6Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatiset quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas"
    },
    {
        image: ReviewImage2,
        name: "John Smith",
        role: "Organiser at Lotus Event",
        testimonyMsg: "7Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatiset quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas"
    },
    {
        image: ReviewImage3,
        name: "John Smith",
        role: "Organiser at Lotus Event",
        testimonyMsg: "8Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatiset quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas"
    },
    {
        image: ReviewImage4,
        name: "John Smith",
        role: "Organiser at Lotus Event",
        testimonyMsg: "9Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatiset quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas"
    },
    {
        image: ReviewImage5,
        name: "John Smith",
        role: "Organiser at Lotus Event",
        testimonyMsg: "10Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatiset quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas"
    }
];