import React, {useEffect} from 'react'
import {Row, Col, Container } from 'react-bootstrap';
import "./BlogButton.scss";

export const FacebookCanHelpSuccessEvent = () => {
    useEffect(() => {
        window.scrollTo({ top: 0, behavior: "smooth" });
      }, [])
    return (   
    <>
    <Container>
    <div className="fb-blog-hero inner-hero-phone">
        <h1 className="vertical-center">Blog<span>How facebook can help?</span></h1>
        
    </div>
        <Row className="padding-y-40 align-items-center paragraph-wrpr-row side-pattern">
        <Col md={12} className="top-space">
            <div className="li-wrapper">
            <p>Learning about various types of fund raising activities will help you benefit from raising funds for your organization in a better way. Here is brief description of some of the most common types of fund raising activities. These are very popular among people across the globe. It is important to get to gather information on these to benefit from the best.</p>
            <p>
            <h2 className='blog-subtitle blogtop-margin-30'>How facebook can help you for success of event?</h2>
            Facebook is dynamic platform to endorse your product, business, services and events. If we talk about the event management industry then, it is understood and witnessed that Facebook plays an important part in its success. The major events are promoted through Facebook and crowd is generated to make an event a success. No matters whatever the category of your event is, you can easily get the response of the targeted audience using the advanced and productive features of Facebook. Here are some FB features that can help your event in getting successful:
            <p className='blogtop-margin-30'><strong>Facebook Event Page</strong>- Through this feature, you can create an event page containing the details of your event and can also explain the motto of the event on the same. Facebook event page will help you in providing the core information of your event to the targeted people. You can invite your Facebook friends and group members to the event page and can ask them to purchase the tickets of your event.s.</p>
            <p className='blogtop-margin-30'><strong>Event Promotion</strong> - Any random events achieve success only when it is properly advertised, endorsed and explained in a proper manner. Facebook is a perfect medium to represent all the required factors effectively. You can ask your friends, group members and followers to follow, like and share your event details, so that more and more people can get to know about it and can purchase the ticket for the same.</p>
            <p className='blogtop-margin-30'><strong>Link your Main Event Page to Facebook</strong>- You should link the main event page to Facebook, so that people associated with you can have the latest update on the same. Also, people who have purchased the ticket can also have the update about the event.</p>
            <p className='blogtop-margin-30'><strong>Link the FB Event Page with Social Media</strong>- There are few social media sites that can be helpful to you in getting the ticket buyers. You can place the FB event page link on your other social media accounts, so that you can advertise your event on additional platform and can target the users, friends and group members of such sites.</p>
            <p className='blogtop-margin-30'><strong>Promotion through your Profile</strong>- Other than creating a fan page or event page on Facebook, you can even advertise your event on your profile as it will get a quick response than an event or fan page.</p>
            <p className='blogtop-margin-30'>However, Facebook can be used for event promotion in other ways as well but the above discussed are the most accepted and productive ways.</p>

            Thank you,
                </p>
            </div>
        </Col>
        </Row>
        
     </Container>
    </>
     )
}