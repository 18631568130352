import React, {useEffect} from 'react'
import { Container } from 'react-bootstrap';
import { Accordion } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import "./SaleQuestion.scss";

export const SaleQuestion = () => { 
    useEffect(() => {
        window.scrollTo({ top: 0, behavior: "smooth" });
      }, [])

    return (
    <>
    <Container>

    <div className="sale-question-hero inner-hero-phone">
    <h1 className="vertical-center">Pre-sales Question</h1>
    </div>
        <div className='padding-y-70 padding-phone-30 align-items-center paragraph-wrpr-row'>
        <div className="li-wrapper">
        <p>After purchasing Basic package, We will send you request to provide domain name for license purpose. 
            Within 24 hours, you will receive script in email. Same method works for other packages except 
            extended package.</p>
        <p className='mt-3'>After purchasing extended package, one of our sales executives will email you and request you to 
            provide your entire design requirements. Basically, we will send couple of questions regarding design
            and from your answer, we will start doing design and complete all design page. After this phase, 
            we will provide estimate days for completion of project . that could be from 60 days to 70 days 
            (including development , software testing , transfer your server and again testing). 
            We will not provide you any source code or software during extended package to work at your place.
            once software will transfer to your server same day support period will start.</p>
        <p className='mt-3'><strong>Terms & Conditions: Payments are non-refundable, which means after making payment we will not 
            refund any money for any product or service. So before making any decision make sure you do your 
            homework and clear all the question and doubts</strong></p>
        <p className='mt-3'>Technical Question and Answer : Please discuss all this point with your developer or your development team.</p>
        </div>
        <div className="accordion-box margin-bottom-110 mt-lg-5 mt-sm-3">  
        <Accordion>
    <Accordion.Item eventKey="0" className="accordian-properties">
        <Accordion.Header>Is this software come with domain license?</Accordion.Header>
        <Accordion.Body>
        We are providing all the software with domain license basis. It include small portion of encryption of 
        programming file. We also provide 100% surety that encryption will not create any problem during 
        customization or modification of software.
        </Accordion.Body>
    </Accordion.Item>
    <Accordion.Item eventKey="1" className="accordian-properties">
        <Accordion.Header>How many domains I can use this software on?</Accordion.Header>
        <Accordion.Body>
        In basic package 1 domain, In open source and hire developer package you will be allowed to use ticketing 
        software on one domain only but up to 3 and 5 domain TLDs in open source and hire developer package 
        respectively. Like you can use your domain on .com, .net, .org or any other domain TLD as you want(but up to 3 and 5).
        </Accordion.Body>
    </Accordion.Item>
    <Accordion.Item eventKey="2" className="accordian-properties">
        <Accordion.Header>Do you offer ticketing software that satisfies all my customization needs?</Accordion.Header>
        <Accordion.Body>
        Yes, we do all the customization as per client’s requirement as it comes down under our main services
        list. We will provide estimate hours for each customization and request you to pay upfront before 
        starting the same. We always request customers to provide detailed customization requirement including 
        visual files and document files. It will reduce risk of misunderstanding and provide ease to 
        complete the customization work. Here is wiki link :<a target="_blank" href="https://en.wikipedia.org/wiki/Software_documentation#Requirements_documentation"> Click here</a>
        </Accordion.Body>
    </Accordion.Item>
    <Accordion.Item eventKey="3" className="accordian-properties">
        <Accordion.Header>Can I modify the ticketing software according to my requirements?</Accordion.Header>
        <Accordion.Body>
        Yes, you are able to modify code. We are not providing our branding or any trademark with software. 
        Once you purchase, It means it is your software and you are the boss. You can modify according to your 
        requirement. For all kind of modification, you need professional developer help. There is nothing like 
        word press plug-in or other open source where you can do modification from admin.
        </Accordion.Body>
    </Accordion.Item>
    <Accordion.Item eventKey="4" className="accordian-properties">
        <Accordion.Header>Can I change language myself?</Accordion.Header>
        <Accordion.Body>
        You need software developer help to change language of software. It will affect CSS and HTML.
        We can provide guide line for file structure but we are not providing any support of CSS and HTML 
        during your language change.
        </Accordion.Body>
    </Accordion.Item>
    <Accordion.Item eventKey="5" className="accordian-properties">
        <Accordion.Header>What support does the company provide and how long?</Accordion.Header>
        <Accordion.Body>
        Each package have own support period. You will able to find from here.
        </Accordion.Body>
    </Accordion.Item>
    <Accordion.Item eventKey="6" className="accordian-properties">
        <Accordion.Header>Does company offer future update?</Accordion.Header>
        <Accordion.Body>
        In basic package, we are offering future updates for certain period of time.
        </Accordion.Body>
    </Accordion.Item>
    <Accordion.Item eventKey="7" className="accordian-properties">
        <Accordion.Header>Unique websites offered to each individual?</Accordion.Header>
        <Accordion.Body>
        Yes. It will cover in extended package. In extended package, we are providing unique websites to customer
        and do all the customization according to requirement. It will take time but result will be professional
        and provide high satisfaction. We will request you keep time frame open and coordinate with our 
        developer team to find exact time frame for your project.
        </Accordion.Body>
    </Accordion.Item>
    <Accordion.Item eventKey="8" className="accordian-properties">
        <Accordion.Header>What are the payment services available?</Accordion.Header>
        <Accordion.Body>
        Currently we integrate Paypal.com. We know that paypal.com do not support few countries and currencies.
        In case, paypal.com is not supporting your country or currency than contact us and request quote for 
        local payment gateway integration. Please provide sandbox detail of your local payment gateway and all 
        documentation to save our and your valuable time. We will not be able to provide quote if you don’t
        have any documentation or API details.
        </Accordion.Body>
    </Accordion.Item>
    <Accordion.Item eventKey="9" className="accordian-properties">
        <Accordion.Header>Which programming language and frame work used in ticketing software?</Accordion.Header>
        <Accordion.Body>
        We used Codeigniter <a target="_blank" href="https://www.codeigniter.com/">(https://www.codeigniter.com/)</a>, MySQL as database.
        </Accordion.Body>
    </Accordion.Item>
    <Accordion.Item eventKey="10" className="accordian-properties">
        <Accordion.Header>Server requirement?</Accordion.Header>
        <Accordion.Body>
        <Link to="/server-requirements">Check Server Requirements Here</Link>
        </Accordion.Body>
    </Accordion.Item>
    <Accordion.Item eventKey="11" className="accordian-properties">
        <Accordion.Header>What I will do if my project have tight deadline?</Accordion.Header>
        <Accordion.Body>
        We will try to finish your project as per your deadline. We have to hire new resource and try to complete 
        your project. It will increase cost of project too. It is advisable to go for this option when project 
        owner don’t have budget problem.
        </Accordion.Body>
    </Accordion.Item>
    <Accordion.Item eventKey="12" className="accordian-properties">
        <Accordion.Header>How bug fixing support work?</Accordion.Header>
        <Accordion.Body>
        We provide bug fixing for certain period of time for each package. We always request client to test
        application perfectly in provided support period. If you contact us after expire of support period 
        than we will charge you hourly rate to fix the problem.
        </Accordion.Body>
    </Accordion.Item>
    <Accordion.Item eventKey="13" className="accordian-properties">
        <Accordion.Header>I need live support or skype support need?</Accordion.Header>
        <Accordion.Body>
        Please contact us, We will see your problem and if we feel it is really good solution than we will
        provide on chat support free else we will charge you and yes, we are available on chat support.
        </Accordion.Body>
    </Accordion.Item>
    <Accordion.Item eventKey="14" className="accordian-properties">
        <Accordion.Header>How customization parts work?</Accordion.Header>
        <Accordion.Body>
        We do all the customization on hourly basis. We are accepting min 10 hours customization. 
        Someone from our team will available on chat and take your customization requirement and provide 
        estimate hours for customization.
        </Accordion.Body>
    </Accordion.Item>
    <Accordion.Item eventKey="15" className="accordian-properties">
        <Accordion.Header>Can i have open source software for my project?</Accordion.Header>
        <Accordion.Body>
        Please contact support team, we will try understanding your requirement and provide right 
        solution to you. Please remember it will cost you five times more than current package price.
        </Accordion.Body>
    </Accordion.Item>
        </Accordion>
        </div> 
       
    </div> 
    <div className="li-wrapper sale-content mt-lg-5 mb-lg-5 mt-sm-3 mb-sm-3">
        <p>Always remember : We will create your Ticketing software platform and that will be your brand and your logo even powered by or develop by will be your company name.</p>
        <p>There are no monthly recurring fees after completion of project. We are not charging anything after completion of project.</p>
        </div>      
    </Container>
    </>
)
}
