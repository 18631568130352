import React, {useEffect} from 'react'
import {Row, Col, Container } from 'react-bootstrap';
import "./Demo.scss";


export const  ServerRequirements = () => {
    useEffect(() => {
        window.scrollTo({ top: 0, behavior: "smooth" });
      }, [])
    return (   
    <>
    <Container>
    <div className="demo-hero inner-hero-phone">
        <h1 className="vertical-center"> Server Requirements</h1>
    </div>
        <Row className="padding-y-40 align-items-center paragraph-wrpr-row">
        <Col md={12} className="top-space bottom-space-40">
            <div className="li-wrapper">
            <p>
            <h2 className='blog-subtitle'>Server Requirement</h2>
            Facebook is dynamic platform to endorse your product, business, services and events. If we talk about the event management industry then, it is understood and witnessed that Facebook plays an important part in its success. The major events are promoted through Facebook and crowd is generated to make an event a success. No matters whatever the category of your event is, you can easily get the response of the targeted audience using the advanced and productive features of Facebook. Here are some FB features that can help your event in getting successful:
            <p>Ticketing Software is basically event management software and provide platform to many event organiser to sell event ticket and manage everything from one place.</p>
            <div className='bulletlisting'>
            <ul>
                <li>Linux Operating System (Cent OS Recommended)</li>
                <li>PHP 5.0 (or Upto 5.5)</li>
                <li>mySQL 5 (or Higher)</li>
                <li>GD Library (Available free at http://www.libgd.org)</li>
                <li>IonCube PHP Loader v4.2.2version (Available free at http://www.ioncube.com/loaders.php)</li>
                <li>cURL</li>
                <li>Mod Url_rewrite enabled</li>
                <li>Ability to run at least 2 cron jobs every 5 min</li>
                <li>URI_PROTOCOL enabled to use Facebook and Twitter login</li>
                <li>Open SSL compiled with PHP (fsockopen must be enabled)</li>
                <li>PDO Extension</li>
            </ul>
            </div>
            

            <h2 className='blog-subtitle'>We recommand hostgator.com</h2> 

            Thank you,
                </p>
            </div>
        </Col>
        </Row>
        
     </Container>
    </>
     )
}