import React from "react";
import { Helmet } from "react-helmet";
import { Container } from "react-bootstrap";
import { PricingTable } from "./PricingTable/PricingTable";
import { PricingTableMobile } from "./PricingTable/PricingTableMobile";
import "./Pricing.scss";

export const Pricing = () => {
  document.title = "Pricing: Ticketing Softwares Pricing, Package List for Eventbrite Clone, Ticketing Software";
  document.querySelector('meta[name="description"]').setAttribute(
    "content",
    "Checkout the pricing list for ticketing software script. Contact us now for TicktingSoftware script pricing details."
  );

  return (
    <>
      <Container>
        <div className="Pricing-hero inner-hero-phone">
          <h1 className="vertical-center">Pricing</h1>
        </div>
        <PricingTable />
        {/* {window.innerWidth <= 992 ? <PricingTableMobile /> : <PricingTable />} */}
      </Container>
    </>
  );
};
