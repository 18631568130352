import React, { Component, useState, useRef } from "react";
import { Container } from "react-bootstrap";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "./PricingTableMobile.scss";

function BlogNextArrow(props) {
  const { onClick } = props;
  return (
    <div className="pricing-next-btn" onClick={onClick}>
      <FontAwesomeIcon icon={["fas", "caret-left"]} />
    </div>
  );
}

function BlogPrevArrow(props) {
  const { onClick } = props;
  return (
    <div className="pricing-previous-btn" onClick={onClick}>
      <FontAwesomeIcon icon={["fas", "caret-right"]} />
    </div>
  );
}

export const PricingTableMobile = () => {
  const [slider, setSlider] = useState({
    slideIndex: 0,
    updateCount: 0,
  });
  const sliderRef = useRef();
  const settings = {
    dots: false,
    arrows: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <BlogNextArrow />,
    prevArrow: <BlogPrevArrow />,
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };
  return (
    <>
      <Container>
        <section className="margin-top-100 bottom-margin-80">
          <div>
            <div className="pricing-wrapper g-0">
              <Slider ref={(sliderRef) => sliderRef} {...settings}>
                <div className="h-100">
                  <div className="pricing-list">
                    <ul>
                      <li className="pricing-head">
                        <div className="package-name fh-103">
                          <h3>Basic</h3>
                          <h6 className="lh-base">
                            Recommended for Mid size Startup owners, Mid size
                            Businesses, Custom Design & Branding
                          </h6>
                        </div>
                      </li>
                      <li>
                        <span className="li-text-moble">
                          Fully featured ticketing software
                        </span>
                        <FontAwesomeIcon icon={["fas", "check"]} size="lg" />
                      </li>
                      <li>
                        <span className="li-text-moble text-secondary">
                          Custom Design
                        </span>
                        <FontAwesomeIcon icon={["fas", "times"]} size="lg" />
                      </li>
                      <li>
                        <span className="li-text-moble">Responsive Design</span>
                        <FontAwesomeIcon icon={["fas", "check"]} size="lg" />
                      </li>
                      <li>
                        Domain License 1{" "}
                        <FontAwesomeIcon icon={["fas", "check"]} size="lg" />
                      </li>
                      <li>
                        Encrypted Files{" "}
                        <FontAwesomeIcon icon={["fas", "check"]} size="lg" />
                      </li>

                      <li className="lh-sm">
                        1 time free Installation & Configuration{" "}
                        <FontAwesomeIcon icon={["fas", "check"]} size="lg" />
                      </li>
                      <li>
                        Latest version{" "}
                        <FontAwesomeIcon icon={["fas", "check"]} size="lg" />
                      </li>
                      <li>
                        <span className="li-text-moble">
                          Detailed Documentation
                        </span>
                        <FontAwesomeIcon icon={["fas", "check"]} size="lg" />
                      </li>
                      <li>
                        Call Duration 5 Days
                        <FontAwesomeIcon icon={["fas", "check"]} size="lg" />
                      </li>

                      <li>
                        <span className="li-text-moble">Version Updates</span>
                        <FontAwesomeIcon icon={["fas", "check"]} size="lg" />
                      </li>
                      <li>
                        <span className="li-text-moble">Bug Fixing</span>
                        <FontAwesomeIcon icon={["fas", "check"]} size="lg" />
                      </li>
                      <li>
                        <span className="li-text-moble">
                          Technical issues assistance
                        </span>
                        <FontAwesomeIcon icon={["fas", "check"]} size="lg" />
                      </li>
                      <li>
                        Free Support Period 180 Days
                        <FontAwesomeIcon icon={["fas", "check"]} size="lg" />
                      </li>
                      <li className="fh-80 lh-sm">
                        Customer Support: Email, Support Ticket, Live Chat
                        <FontAwesomeIcon icon={["fas", "check"]} size="lg" />
                      </li>
                      <li className="fh-80 text-secondary">
                        <span className="li-text-moble">Team</span>
                        <FontAwesomeIcon icon={["fas", "times"]} size="lg" />
                      </li>
                      <li className="fh-80 text-secondary">
                        <span className="li-text-moble">
                          Management Includes
                        </span>
                        <FontAwesomeIcon icon={["fas", "times"]} size="lg" />
                      </li>

                      <li>
                        <span className="li-text-moble">Signing Contract</span>
                        <FontAwesomeIcon icon={["fas", "check"]} size="lg" />
                      </li>

                      <li>
                        <Link to="#">Click Here</Link>&nbsp;
                        <span>Terms & Conditions</span>
                      </li>
                      <li>
                        <Link to="#">Click Here</Link>&nbsp;
                        <span>Server Requirements</span>
                      </li>
                      <li className="pricing_cta">
                        <Link className="orange-btn" to="/contact">
                          Contact Today
                          <span className="tri-btn">
                            <FontAwesomeIcon
                              icon={["fas", "arrow-right"]}
                              size="lg"
                            />
                          </span>
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>

                <div className="h-100">
                  <div className="pricing-list">
                    <ul>
                      <li className="pricing-head">
                        <div className="package-name fh-103">
                          <h3>Open Source</h3>
                          <h6 className="lh-base">
                            Recommended for Large size Startup owners, Large
                            size Businesses, Developers
                          </h6>
                        </div>
                      </li>
                      <li>
                        <span className="li-text-moble">
                          Fully featured ticketing software
                        </span>
                        <FontAwesomeIcon icon={["fas", "check"]} size="lg" />
                      </li>
                      <li>
                        <span className="li-text-moble text-secondary">
                          Custom Design
                        </span>
                        <FontAwesomeIcon icon={["fas", "times"]} size="lg" />
                      </li>
                      <li>
                        <span className="li-text-moble">Responsive Design</span>
                        <FontAwesomeIcon icon={["fas", "check"]} size="lg" />
                      </li>
                      <li className="text-secondary">
                        Domain License 1{" "}
                        <FontAwesomeIcon icon={["fas", "times"]} size="lg" />
                      </li>
                      <li className="text-secondary">
                        Encrypted Files{" "}
                        <FontAwesomeIcon icon={["fas", "times"]} size="lg" />
                      </li>

                      <li className="lh-sm">
                        Installation & Configuration 1 time free{" "}
                        <FontAwesomeIcon icon={["fas", "check"]} size="lg" />
                      </li>
                      <li>
                        Latest version{" "}
                        <FontAwesomeIcon icon={["fas", "check"]} size="lg" />
                      </li>
                      <li>
                        <span className="li-text-moble">
                          Detailed Documentation
                        </span>
                        <FontAwesomeIcon icon={["fas", "check"]} size="lg" />
                      </li>
                      <li>
                        Call Duration 5 Days
                        <FontAwesomeIcon icon={["fas", "check"]} size="lg" />
                      </li>

                      <li>
                        <span className="li-text-moble">Version Updates</span>
                        <FontAwesomeIcon icon={["fas", "check"]} size="lg" />
                      </li>
                      <li>
                        <span className="li-text-moble">Bug Fixing</span>
                        <FontAwesomeIcon icon={["fas", "check"]} size="lg" />
                      </li>
                      <li>
                        <span className="li-text-moble">
                          Technical issues assistance
                        </span>
                        <FontAwesomeIcon icon={["fas", "check"]} size="lg" />
                      </li>
                      <li>
                        Free Support Period 1 Year{" "}
                        <FontAwesomeIcon icon={["fas", "check"]} size="lg" />
                      </li>
                      <li className="fh-80 lh-sm">
                        Customer Support: Email, Support Ticket, Live Chat,
                        Skype, Phone{" "}
                        <FontAwesomeIcon icon={["fas", "check"]} size="lg" />
                      </li>

                      <li className="fh-80 text-secondary">
                        <span className="li-text-moble">Team</span>
                        <FontAwesomeIcon icon={["fas", "times"]} size="lg" />
                      </li>
                      <li className="fh-80 text-secondary">
                        <span className="li-text-moble">
                          Management Includes
                        </span>
                        <FontAwesomeIcon icon={["fas", "times"]} size="lg" />
                      </li>

                      <li>
                        <span className="li-text-moble">Signing Contract</span>
                        <FontAwesomeIcon icon={["fas", "check"]} size="lg" />
                      </li>

                      <li>
                        <Link to="#">Click Here</Link>&nbsp;
                        <span>Terms & Conditions</span>
                      </li>
                      <li>
                        <Link to="#">Click Here</Link>&nbsp;
                        <span>Server Requirements</span>
                      </li>
                      <li className="pricing_cta">
                        <Link className="orange-btn" to="/contact">
                          Contact Today
                          <span className="tri-btn">
                            <FontAwesomeIcon
                              icon={["fas", "arrow-right"]}
                              size="lg"
                            />
                          </span>
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>

                <div className="h-100 highlight_block_mobile">
                  <div className="highlight_heading_box">
                    <div className="highlighted_heading fh-103">
                      <h3>Hire Developer</h3>
                      <h6 className="lh-base">
                        Recommended for Large size Startup owners, Large size
                        Businesses, Custom Event Platform Ideas
                      </h6>
                    </div>
                  </div>
                  <div className="pricing-list higlighted_clm">
                    <ul>
                      <li>
                        <span className="li-text-moble">
                          Fully featured ticketing software
                        </span>
                        <FontAwesomeIcon icon={["fas", "check"]} size="lg" />
                      </li>
                      <li>
                        <span className="li-text-moble">Custom Design</span>
                        <FontAwesomeIcon icon={["fas", "check"]} size="lg" />
                      </li>
                      <li>
                        <span className="li-text-moble">Responsive Design</span>
                        <FontAwesomeIcon icon={["fas", "check"]} size="lg" />
                      </li>
                      <li>
                        Domain License 1{" "}
                        <FontAwesomeIcon icon={["fas", "check"]} size="lg" />
                      </li>
                      <li>
                        Encrypted Files{" "}
                        <FontAwesomeIcon icon={["fas", "check"]} size="lg" />
                      </li>

                      <li className="lh-sm">
                        Installation & Configuration 5 time free{" "}
                        <FontAwesomeIcon icon={["fas", "check"]} size="lg" />
                      </li>
                      <li>
                        Latest version{" "}
                        <FontAwesomeIcon icon={["fas", "check"]} size="lg" />
                      </li>
                      <li>
                        <span className="li-text-moble">
                          Detailed Documentation
                        </span>
                        <FontAwesomeIcon icon={["fas", "check"]} size="lg" />
                      </li>
                      <li className="text-secondary">
                        <span className="li-text-moble">Call Duration</span>
                        <FontAwesomeIcon icon={["fas", "times"]} size="lg" />
                      </li>

                      <li>
                        <span className="li-text-moble">Version Updates</span>
                        <FontAwesomeIcon icon={["fas", "check"]} size="lg" />
                      </li>
                      <li>
                        <span className="li-text-moble">Bug Fixing</span>
                        <FontAwesomeIcon icon={["fas", "check"]} size="lg" />
                      </li>
                      <li>
                        <span className="li-text-moble">
                          Technical issues assistance
                        </span>
                        <FontAwesomeIcon icon={["fas", "check"]} size="lg" />
                      </li>
                      <li>
                        Free Support Period 1 Year{" "}
                        <FontAwesomeIcon icon={["fas", "check"]} size="lg" />
                      </li>
                      <li className="fh-80 lh-sm">
                        Customer Support: Email, Support Ticket, Live Chat,
                        Skype, Phone
                        <FontAwesomeIcon icon={["fas", "check"]} size="lg" />
                      </li>

                      <li className="lh-sm fh-80">
                        Team: 1 Developer, 1 Project Manager{" "}
                        <FontAwesomeIcon icon={["fas", "check"]} size="lg" />
                      </li>
                      <li className="lh-sm fh-80">
                        Management Includes Customer support, Technical issues
                        assistance
                        <FontAwesomeIcon icon={["fas", "check"]} size="lg" />
                      </li>

                      <li>
                        <span className="li-text-moble">Signing Contract</span>
                        <FontAwesomeIcon icon={["fas", "check"]} size="lg" />
                      </li>
                      <li>
                        <Link to="#">Click Here</Link>&nbsp;
                        <span>Terms & Conditions</span>
                      </li>
                      <li>
                        <Link to="#">Click Here</Link>&nbsp;
                        <span>Server Requirements</span>
                      </li>
                      <li className="pricing_cta">
                        <Link className="blue-btn" to="/contact">
                          Contact Today
                          <span className="tri-btn">
                            <FontAwesomeIcon
                              icon={["fas", "arrow-right"]}
                              size="lg"
                            />
                          </span>
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </Slider>
            </div>
          </div>
        </section>
      </Container>
    </>
  );
};
