import React from "react";
import BrandLogo from "../../img/logo.png";
import { Row, Col, Container } from "react-bootstrap";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Nav } from "react-bootstrap";
import { Navbar } from "react-bootstrap";
import { WebImage } from '../Image/WebImage';
import "./Header.scss";

export const Header = () => {
  return (
    <>
      <Container className="secondary-menu">
        <Row className="secondary-menu-row">
          <Col md={12}>
            <div className="phone-number">
              <p>
                Call Us <a href="tel:+91 787-433-0444">+91 940-828-8843</a>
              </p>
            </div>
          </Col>
{/* 
          <Col md={3}>
            <Form className="language_selector">
            <Form.Group as={Col} controlId="formGridState">
           <Form.Select defaultValue="Select Language">
           <option>Select Language</option>
           <option>Arebic</option>
           <option>American</option>
           <option>Bulgerian</option>
           <option>Catalan</option>
          </Form.Select>
         </Form.Group>
            </Form>
          </Col> */}
        </Row>
      </Container>
      <Navbar className="main-nav-section" collapseOnSelect expand="lg" bg="transperant" variant="dark">
        <Container>
          <Link className="navbar-brand" to="/">
            <WebImage src={BrandLogo} alt="" />
          </Link>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" className="toggler-icon"/>
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="me-auto ms-auto">
              <Link className="nav-link" to="/about">
                About
              </Link>
              <Link className="nav-link" to="/features">
                Features
              </Link>
              <Link className="nav-link" to="/demo">
                Demo
              </Link>
              <Link className="nav-link" to="/pricing">
                Pricing
              </Link>
              <Link className="nav-link" to="/blog">
                Blog
              </Link>
              {/* <Link className="nav-link" to="/contact">
                Contact
              </Link> */}
            </Nav>
            <Nav>
              <Link className="nav-link menu-btn" to="/contact">
                {" "}
                Contact Us
                <span className="tri-btn">
                  <FontAwesomeIcon icon={["fas", "arrow-right"]} size="lg" />
                </span>
              </Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  );
};
