import React from 'react'
import AdminFeature from '../../img/AdminSupport.png'
import { Container } from 'react-bootstrap';
import { Row, Col, } from 'react-bootstrap';
import { WebImage } from '../Image/WebImage';
import "./FrontEndFeatures.scss";


export const FrontEndFeatures = () => { 
    React.useEffect(()=>{
        window.scrollTo({top : 0, behavior:"smooth"})
    },[])
    return (
<>
    <Container>
          <div className="frontend-features-hero">
              <h1 className="vertical-center">Front End Features</h1>
          </div> 

          <section className="padding-y-40 bottom-margin-40">

        <Row>
              <Col md={4} className="py-3">
                    <div className="feature-li">
                        <WebImage src={AdminFeature}/>
                    
                        <h4>Advanced Dashboard</h4>
                        <p>
                        The dashboard page allows new users and visitors to see all the information. The advanced dashboard at Ticketing Software is organized into categories for ease of use so that users can easily use the portal to maintain their accounts. You can visit any page directly through the dashboard and update the information. The portal allows flexibility to customize the look of the dashboard to fit your requirements.

                     </p>
                    </div>
                </Col>

                <Col md={4} className="py-3">
                    <div className="feature-li">
                        <WebImage src={AdminFeature}/>
                    
                        <h4>Social Media Support</h4>
                        <p>
                        Our event ticketing system software provides better connectivity with the leading social media platforms. Users can promote their posts on social media sites through our ticketing software and invite friends of friends to visit their page.

                     </p>
                    </div>
                </Col>

                <Col md={4} className="py-3">
                    <div className="feature-li">
                        <WebImage src={AdminFeature}/>
                    
                        <h4>Dynamic Event Information Form</h4>
                        <p>
                        The event ticketing system software submission form is divided into three categories: Add Your Event Details, Create Tickets, and Promote Your Event. The whole event form page is designed keeping the user in mind so that the user can easily explain their event. In addition, the form also supports audio-video and image files.
                     </p>
                    </div>
                </Col>
              </Row>

              <Row>
              <Col md={4} className="py-3">
                    <div className="feature-li">
                        <WebImage src={AdminFeature}/>
                    
                        <h4>Multiple Tabs</h4>
                        <p>
                        The event ticketing system software allows users to utilize the platform easily and completely. The main tabs include - Create an Event, My Profile, My Events, My Contacts, and User Account information. In addition, many sub-tabs allow the user to work within the portal more efficiently.
                     </p>
                    </div>
                </Col>

                <Col md={4} className="py-3">
                    <div className="feature-li">
                        <WebImage src={AdminFeature}/>
                    
                        <h4>Search Tab</h4>
                        <p>
                        The Search Tab is provided where needed to make searching events easy. You will find the search tab in My events to filter your search results.
                     </p>
                    </div>
                </Col>

                <Col md={4} className="py-3">
                    <div className="feature-li">
                        <WebImage src={AdminFeature}/>
                    
                        <h4>Design</h4>
                        <p>
                        We also provide the facility to choose the design from several available designs. We hope you can keep your form looking unique by selecting a design that suits you.

                     </p>
                    </div>
                </Col>
              </Row>

              <Row>
              <Col md={4} className="py-3">
                    <div className="feature-li">
                        <WebImage src={AdminFeature}/>
                    
                        <h4>Account Tab</h4>
                        <p>
                        The main tab allows you to keep track of multiple information, from personal details to financial transactions; each and everything can be managed through the Account Tab.
                     </p>
                    </div>
                </Col>

                <Col md={4} className="py-3">
                    <div className="feature-li">
                        <WebImage src={AdminFeature}/>
                    
                        <h4>Contacts List</h4>
                        <p>
                        This special feature allows you to create a contact list through the event ticketing system software. You can send individual events and group invitations through the list.
                     </p>
                    </div>
                </Col>

                <Col md={4} className="py-3">
                    <div className="feature-li">
                        <WebImage src={AdminFeature}/>
                    
                        <h4>Profile</h4>
                        <p>
                        We also introduced the latest profile format, which allows the users to provide their organizer information effectively and offers the flexibility to customize the profile's look to their liking.
                     </p>
                    </div>
                </Col>
              </Row>

              <Row className="bottom-margin-40">
              <Col md={4} className="py-3">
                    <div className="feature-li">
                        <WebImage src={AdminFeature}/>
                    
                        <h4>Help Centre</h4>
                        <p>
                        This is an equally important feature in Ticketing Software because it guides you through ways to use the platform effectively and offers you additional information.
                     </p>
                    </div>
                </Col>

                <Col md={4} className="py-3">
                    <div className="feature-li">
                        <WebImage src={AdminFeature}/>
                    
                        <h4>Responsive Design</h4>
                        <p>
                        Our Event Ticketing Software has a responsive design that allows customers to interact with it easily. The design itself flows smoothly as you click on each tab, making navigating through it easy.
                        
                     </p>
                    </div>
                </Col>

                <Col md={4} className="py-3">
                    <div className="feature-li">
                        <WebImage src={AdminFeature}/>
                    
                        <h4>Seating Map</h4>
                        <p>
                        Look at the seating map and book or assign tickets accordingly. Evaluate what your client wants and provide them with what is sure to make them feel satisfied.

                     </p>
                    </div>
                </Col>
              </Row>
              {/* <div className="feature-cta">
                     <Link className="blue-btn" to="/admin-features">Features
                     
                     <span className="tri-btn">
                     <FontAwesomeIcon icon={['fas', 'arrow-right']} size="lg"/>
                     </span>
                     
                     </Link>
                     </div> */}
        </section>  
          
            
    
    </Container>
</>
)
}