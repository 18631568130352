import React, {useEffect} from 'react'
import {Row, Col, Container } from 'react-bootstrap';
import "./BlogButton.scss";


export const MajorRoleSocialMedia = () => {
    useEffect(() => {
        window.scrollTo({ top: 0, behavior: "smooth" });
      }, [])
      
    return (   
    <>
    <Container>
    <div className="major-role-blog-hero inner-hero-phone">
        <h1 className="vertical-center">Blog<span>Major Role of Social Media.</span></h1>
    </div>
        <Row className="padding-y-40 align-items-center paragraph-wrpr-row side-pattern">
        <Col md={12} className="top-space">
            <div className="li-wrapper">
            <p>
            <h2 className='blog-subtitle blogtop-margin-30'>MAJOR ROLE OF SOCIAL MEDIA FOR EVENT SUCCESS</h2>
            Event can be successful only when they are properly advertised and promoted through suitable medium. It is very essential to find out the one medium through which an event organizer can promote the upcoming event effective and can reach to the masses through the same. Present era is driven by the internet and hence, one should use this medium to promote the event. However, there are various options available under the internet categories through which event organizer can make the way to the targeted audience but, the social media is the most vibrant and efficient platform to quickly get in touch with crowd
            <p>Social media platforms not only participate in promotion of events but, they are also used as a medium to update the people about the event details and relevant things.</p>
            <h2 className='blog-subtitle blogtop-margin-30'>WHAT IS THE SIGNIFICANCE OF SOCIAL MEDIA IN EVENT SUCCESS?</h2>
            <p>When an event organizer promotes his upcoming event through popular social media platforms then, it quickly gets the response. The reason responsible for immediate response is that popular social medial platforms have millions of active users and they offer many features to connect with them. Event organizer through many supportable features offered by social media platforms can transfer the event details to uncountable users. So, interested people can attend the event after getting aware about it on their social media accounts. That is how event organizer can gather the required crowd to make the event successful. This clearly indicates the importance of social media in event success.</p>
            <p><strong>Link your Main Event Page to Facebook</strong> - You should link the main event page to Facebook, so that people associated with you can have the latest update on the same. Also, people who have purchased the ticket can also have the update about the event.</p>
            <h2 className='blog-subtitle blogtop-margin-30'>WHICH ARE THE BEST SOCIAL MEDIA PLATFORMS TO PROMOTE THE EVENT?</h2>
            <p>Though, there are lots of social media platforms active on the web but, the few popular are enough to effectively address and promote your event. The top social media platforms, where you can promote your events are as follows:</p>
            <p><strong>Twitter</strong> – It has the highest number of active users. Through tweets, you can promote your event on the same.</p>
            <p><strong>Facebook</strong>– It is the most loved social media platform. Facebook offer features, like – create fan page, create business page, create event page and others. All these features can be helpful for the event organizer to effectually promote his event on Facebook.</p>
            <p><strong>Linked-In</strong>– It is highly professional social media platform. Linked-In is meant to promote your business, services, products and related affairs on it. Here, one can easily promote his event and can get the quality response from the standard crowd.</p>
            <p>Event organizer can even promote the events on other social media platforms as per his interest and comfort but, should not skip the above mentioned platforms.</p>
            </p>
            </div>
        </Col>
        </Row>
        
     </Container>
    </>
     )
}