import React, {useEffect} from 'react'
import { Helmet } from "react-helmet";
import AvailableDemoImg from '../../img/available_version_img.png';
import DemoVersionImg from '../../img/demo_version_img.png'
import { Container } from 'react-bootstrap';
import { Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import DemoOne from '../../img/FrontEndImg.png';
import Iphone from '../../img/PhoneAppOne.png';
import Android from '../../img/PhoneAppOne.png';
import DemoTwo from '../../img/AdminDemoImg.png';
import "./Demo.scss";

export const Demo = () => { 
    useEffect(() => {
        window.scrollTo({ top: 0, behavior: "smooth" });
      }, [])
      document.title = "Demo: Eventbrite Clone, Ticketing Softwares, Free Demo Ticketing Software";
      document.querySelector('meta[name="description"]').setAttribute(
        "content",
        "Check demo of TicketingSoftwares now and contact us if you would like to buy script. Kickstart your online ticket marketplace now."
    );

    return (
        <>
            <Container>
                <div className="demo-hero inner-hero-phone">
                    <h1 className="vertical-center">Demo</h1>
                </div> 

                <Row className="margin-top-80 align-items-center paragraph-wrpr-row">
                    <p className="mb-4">
                    Having kept all general and crucial aspects in mind, we have created 
                    a demo version of the features of Ticketing Software. This provides 
                    resourceful advantages to both the clients and the event organizers. 
                    With this, buyers can buy tickets for any particular event posted on 
                    the software or site and quickly test out all its features.
                    </p>
                <Col xs={12} md={5} className="order-md-2 order-sm-1">
                        <div className="right-img">
                            <img src={DemoVersionImg}/>      
                        </div>
                        </Col>
                <Col xs={12} md={7} className="order-md-1 order-sm-2">
                        <div>
                            <h3>
                            Why Use Our Online Ticketing Software Demo Versions First?
                            </h3>
                            <p>
                            We support smart money moves, which is why we provide you with demo 
                            versions of our online ticketing software. We believe you should 
                            test the security, reliability, and accessibility of the feature 
                            in our software before purchasing. This way, you know exactly what
                            you are signing up for before you buy. You can go through all the 
                            available demos and decide whether you want to purchase or pass on 
                            our ticketing system.
                            </p>
                        </div>
                        </Col>
                </Row>

                <Row className="padding-y-40 align-items-center paragraph-wrpr-row">
                <Col xs={12} md={5}>
                        <div className="left-img">
                            <img src={AvailableDemoImg}/>      
                        </div>
                        </Col>
                <Col xs={12} md={7}>
                        <div>
                            <h3>
                            What is Available in the Demo Versions?
                            </h3>
                            <p>
                            We provide demo versions of Ticketing Software, including front-end and 
                            admin panel demos. The front-end software demo guides users through the 
                            Organizer Features, while the admin panel software demo focuses on the 
                            accessibility of administration. Administrators can seek, retrieve, and 
                            manage all the Admin Features in the admin panel demo.
                            </p>
                        </div>
                        </Col>
                </Row>


                <section className="padding-y-80 bottom-margin-80 demo-detail-section">
                    <div className="center-heading">
                        <h2>
                        <span className="font-light">Online Ticketing Software</span> 
                        <span className="orange-text"> Demo</span>
                        </h2>
                        </div>
                    <Row className="padding-y-40 tab-flex-direction">
                        <Col lg={4} md={12} className="mb-phone-20">
                        <div className="demo-img-wrapper tab-mb-20">
                            <img src={DemoOne}/>
                        </div>
                        </Col>
                        <Col lg={8} md={12} className="column-bg">
                        <div className="demo-content-wrapper">
                            <h3>
                                Front End Demo
                            </h3>
                            <p>
                            For user login details, please create 
                            your own user account using your valid email 
                            address from here.
                            </p>
                            <a className="orange-btn mt-2 phone-center" href="https://ticketingsoftwares.com/live/" target="_blank">Try Demo
                            <span className="tri-btn">
                            <FontAwesomeIcon icon={['fas', 'arrow-right']} size="lg"/>
                            </span>
                            </a>
                        </div>
                        </Col> 
                    </Row>   
                    
                    <Row className="padding-y-40 tab-flex-direction">
                        <Col lg={4} md={12} className="mb-phone-20">
                        <div className="demo-img-wrapper tab-mb-20">
                            <img src={DemoTwo}/>
                        </div>
                        </Col>
                        <Col lg={8} md={12} className="column-bg">
                        <div className="demo-content-wrapper">
                            <h3>
                                Admin Demo
                            </h3>
                            <p><span style={{color:"#000", fontWeight:600}}>Username:</span> admin</p>
                            <p><span style={{color:"#000", fontWeight:600}}>Password:</span> 12345678</p>
                            <a className="orange-btn mt-2 phone-center" href="https://ticketingsoftwares.com/live/admin" target="_blank">Try Demo
                            <span className="tri-btn">
                            <FontAwesomeIcon icon={['fas', 'arrow-right']} size="lg"/>
                            </span>
                            </a>
                        </div>
                        </Col> 
                    </Row>   

                </section>

                <section className="bottom-margin-40 demo-detail-section">
                    <div className="center-heading">
                        <h2>
                        <span className="font-light">Mobile</span> 
                        <span className="blue-text"> Application</span>
                        </h2>
                        </div>
                    <Row className="padding-y-40 tab-flex-direction">
                        <Col lg={4} md={12} className="mb-phone-20">
                        <div className="demo-img-wrapper tab-mb-20">
                            <img src={Iphone}/>
                        </div>
                        </Col>
                        <Col lg={8} md={12} className="column-bg blue-bg">
                        <div className="demo-content-wrapper h-blue">
                            <h3>
                                iPhone App
                            </h3>
                            <p>

                            The <span style={{color:"#000", fontWeight:600}}>Check-In Manager</span>&nbsp;
                            <span style={{color:"#2C78FF", fontWeight:600}}>iPhone app (free with Basic, Extended, and Open Source Package) </span>
                            focuses on giving event managers working with the software a chance to explore its 
                            design. With this demo, visitors can check their event attendees and get easy 
                            accessibility, multiple events, attendee coverage, an upper hand over the competition, 
                            and quickly scale up their business, simply from their iPhone devices.
                            </p>
                            
                            <a className="blue-btn mt-2 phone-center" href="https://apps.apple.com/us/app/check-in-manager-for-ticketing/id1147168866?ct=ticketingsoftwares.com" target="_blank">Try Demo
                            <span className="tri-btn">
                            <FontAwesomeIcon icon={['fas', 'arrow-right']} size="lg"/>
                            </span>
                            </a>
                            
                        </div>
                        </Col> 
                    </Row>   
                    
                    <Row className="padding-top-40 bottom-margin-80 tab-flex-direction">
                        <Col lg={4} md={12} className="mb-phone-20">
                        <div className="demo-img-wrapper tab-mb-20">
                            <img src={Android}/>
                        </div>
                        </Col>
                        <Col lg={8} md={12} className="column-bg blue-bg">
                        <div className="demo-content-wrapper h-blue" id="mobileApp-demo">
                            <h3>
                            Android Demo
                            </h3>
                            <p>
                            The <span style={{color:"#000", fontWeight:600}}>Check-In Manager</span> <span style={{color:"#2C78FF", fontWeight:600}}>Android app (free with Basic, Extended, and Open Source Package)</span> focuses 
                            on giving event managers working with the software a chance to explore its design. With this 
                            demo, visitors can quickly check their event attendees and get easy accessibility, multiple events, 
                            attendee coverage, an upper hand over the competition, and quickly scale up their business, simply 
                            from their Android devices. 
                            </p>
                            
                            
                            <a className="blue-btn mt-2 phone-center" href="https://play.google.com/store/apps/details?id=com.rockerstech.ticketing_software" target="_blank">Try Demo
                            <span className="tri-btn">
                            <FontAwesomeIcon icon={['fas', 'arrow-right']} size="lg"/>
                            </span>
                            </a>
                            
                        </div>
                        </Col> 
                    </Row>   

                </section>  
            
            </Container>

            <section className="eop-cta-wrapper">
                <Container>
                    <Row className="demo-cta-bar">
                        <Col lg={9} md={12}>
                            <div className="cta-text-wrapper">
                                <p>
                                    We have designed the software keeping all 
                                    general and crucial aspects in mind so you 
                                    can easily test all the features of the software 
                                    by getting the demo version.
                                </p>
                            </div>
                        </Col>

                        <Col lg={3} md={6} className="cta-wrapper">
                        <Link className="server-btn orange-btn phone-center" to="/server-requirements">Server Requirements
                            <span className="tri-btn">
                            <FontAwesomeIcon icon={['fas', 'arrow-right']} size="lg"/>
                            </span>
                            </Link>
                        </Col>
                    </Row>
                </Container>    
            </section>  
        </>
    ) 
}