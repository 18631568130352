import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useForm } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import classNames from "classnames";
import axios from "axios";
import {
	loadCaptchaEnginge,
	LoadCanvasTemplateNoReload,
	validateCaptcha
} from "react-simple-captcha";
import CustomerSupport from "../../img/customer-support.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Container, Spinner } from "react-bootstrap";
import { Row, Col, Nav } from "react-bootstrap";
import { Form } from "react-bootstrap";
import { WebImage } from "../Image/WebImage";
import Button from "@restart/ui/esm/Button";
import { Link } from "react-router-dom";
import "./Contact.scss";

export const Contact = () => {
  const [successMsg, setSuccessMsg] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  const [loader, setLoader] = useState(false);

  document.title = "Contact Ticketingsoftwares.com, Ticketing Softwares Support";
  document.querySelector('meta[name="description"]').setAttribute(
    "content",
    "Have queries?, want to buy?, want free trial?, have concerns? Have suggestions? contact us now"
  );

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    loadCaptchaEnginge(6)
  }, [])

  const {
		register,
		handleSubmit,
		formState: { errors },
    reset,
    setValue
	} = useForm({
		mode: "onChange"
	});

  const onSubmitContact = async data => {
    setLoader(true);
    const alertElement = document.getElementById("contactForm");
    let contactFormData = new FormData();
    contactFormData.append("username", "ticketrockers");
    contactFormData.append("txtname", data.txtname);
    contactFormData.append("txtemail", data.txtemail);
    contactFormData.append("phoneNumber", data.phoneNumber);
    contactFormData.append("packageSelected", data.packageSelected);
    contactFormData.append("budget", data.budget);
    contactFormData.append("startDate", data.startDate);
    contactFormData.append("txtmsg", data.txtmsg);

    if (validateCaptcha(data.contactCaptcha)) {
      delete data.contactCaptcha;

   await axios.post("https://ticketingsoftwares.com/contact_emails.php", contactFormData)
      .then((response) =>  {
        if (response.data.status) {
          setSuccessMsg(response.data.message);
          setErrorMsg("");
          reset();
        } else {
          setErrorMsg(response.data.message);
          setSuccessMsg("");
        }
        loadCaptchaEnginge(6);
        setLoader(false);
        alertElement.scrollIntoView({behavior: "smooth"});
      })
      .catch((error) => {
        setErrorMsg(error.data.message);
        setLoader(false);
        alertElement.scrollIntoView({behavior: "smooth"});
      })
    } else {
      setValue("contactCaptcha", "");
      setErrorMsg("Invalid captcha entered. Please try again");
      setLoader(false);
      alertElement.scrollIntoView({behavior: "smooth"});
    }
  }

  return (
    <>
      <Container>
        <div className="contact-hero inner-hero-phone">
          <h1 className="vertical-center">Contact</h1>
        </div>

        <section className="margin-top-80">
          <Row>
            <Col md={6}>
              <div className="contact-card">
                <div className="qoute-left"></div>
                <div className="contact-content-wrapper">
                  <div className="text-contact-wrapper">
                    <WebImage src={CustomerSupport} />
                    <p>
                      Our team is always available to help you. If you have
                      Pre-sales question then please visit this page, which will
                      help you to find answer quickly. <Link to="/sale-question">Click Here</Link>. We give equal
                      priority to all our customers.For any inquiry or question.
                      We will get back to you within 24 hours in working
                      days. Our working days are Monday to Friday.
                    </p>

                    <p>
                      Thank you,
                      <br />
                      <span style={{ color: "#2C78FF" }}>
                        Ticketing software, Team
                      </span>
                    </p>
                  </div>
                </div>
                <div className="qoute-right"></div>
              </div>
            </Col>

            <Col md={6}>
              <div className="form-li">
                <h3>Get In Touch</h3>
                <div className="get-in-touch mb-2">
                  <FontAwesomeIcon icon={["fas", "map-marker-alt"]} size="lg" />
                  <p>
                    306- 3rd Floor- Corner Heights, Near Delhi Public School,
                    Ring Road Kalali, Vadodara- 390012, Gujarat, India
                  </p>
                </div>
                <div className="get-in-touch mb-2">
                  <FontAwesomeIcon icon={["fas", "phone-alt"]} size="lg" />
                  <p>+91 940-828-8843</p>
                </div>
                <div className="get-in-touch mb-2">
                <FontAwesomeIcon icon={["fas", "envelope"]} size="lg" />
                  <p>sales@rockersinfo.com</p> 
                </div>

                <div className="social-media">
                <Nav.Link className="social-icon" href="https://www.facebook.com/ticketingsoftware/" target="_blank">      
                <FontAwesomeIcon icon={['fab', 'facebook-f']} size="lg"/>
                </Nav.Link>
                <Nav.Link className="social-icon" href="https://twitter.com/Ticketingsoft" target="_blank"> 
                <FontAwesomeIcon icon={['fab', 'twitter']} size="lg"/>
                </Nav.Link>
                <Nav.Link className="social-icon" href="https://www.linkedin.com/in/ticketing-softwares-949728ba/" target="_blank"> 
                <FontAwesomeIcon icon={['fab', 'linkedin-in']} size="lg"/>
                </Nav.Link>
                <Nav.Link className="social-icon" href="https://www.youtube.com/channel/UCVX7nF97o_UQ1bguY-0DNLw" target="_blank"> 
                <FontAwesomeIcon icon={['fab', 'youtube']} size="lg"/>
                </Nav.Link>
                </div>
              </div>
            </Col>
          </Row>
        </section>
      </Container>

      <section className="padding-y-80" id="contactForm">
        <Container>
          <Row className="gx-5 justify-content-center">
            <Col md={7} className="form_col">
              {successMsg ? 
                <div className="alert alert-primary text-center" role="alert">
                  {successMsg}
                </div> 
              : null}
              {errorMsg ? 
                <div className="alert alert-danger text-center" role="alert">
                  {errorMsg}
                </div> 
              : null}
              <div className="form-title">
                <h3>Drop A Line</h3>
              </div>
              <Form className="form_alignment_phone" name="contactFrom" onSubmit={handleSubmit(onSubmitContact)}>
                <Form.Group className="mb-4">
                  {/* <Form.Label>Name</Form.Label> */}
                  <Form.Control
                    id="contactName"
                    name="txtname"
                    placeholder="Enter your name"
                    className={classNames("form-control", {
                      "is-invalid": errors.txtname
                    })}
                    {...register("txtname", {
                      required: "This field is required.",
                    })}
                  />
                  <ErrorMessage
                    errors={errors}
                    name="txtname"
                    render={({ message }) => (
                      <div className="invalid-feedback" id="contactNameErr">{message}</div>
                    )}
                  />
                </Form.Group>

                <Form.Group className="mb-4">
                  {/* <Form.Label>Email address</Form.Label> */}
                  <Form.Control
                    id="txtemail"
                    name="txtemail"
                    autoComplete="off"
                    data-test="email"
                    type="text"
                    placeholder="Enter your email"
                    className={classNames("form-control", {
                      "is-invalid": errors.txtemail
                    })}
                    {...register("txtemail", {
                      required: "This field is required.",
                      pattern: {
                        value: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                        message: "Invalid email format. Ex: john.smith@example.com"
                      }
                    })}
                  />
                  <ErrorMessage
                    errors={errors}
                    name="txtemail"
                    render={({ message }) => (
                      <div className="invalid-feedback" id="txtemailErr">{message}</div>
                    )}
                  />
                  <Form.Text className="text-muted">
                    We'll never share your email with anyone else.
                  </Form.Text>
                </Form.Group>

                <Form.Group className="mb-4">
                  {/* <Form.Label>Phone Number</Form.Label> */}
                  <Form.Control
                    type="tel"
                    id="contactPhoneNumber"
                    placeholder="Enter your number"
                    className={classNames("form-control", {
                      "is-invalid": errors.phoneNumber
                    })}
                    {...register("phoneNumber", {
                      required: "This field is required.",
                      pattern: {
                        value: /^[0-9]{8,13}$/,
                        message: "Invalid phone number format"
                      }
                    })}
                  />
                   <ErrorMessage
                    errors={errors}
                    name="phoneNumber"
                    render={({ message }) => (
                      <div className="invalid-feedback" id="phoneNumberErr">{message}</div>
                    )}
                  />
                </Form.Group>

                <Form.Group className="dropdown-selector mb-4">
                  <Form.Select
                    aria-label="Default select example"
                    name="packageSelected"
                    id="contactpackageSelected"
                    className={classNames("form-control", {
                      "is-invalid": errors.packageSelected,
                    })}
                    placeholder="In which package are you interested?"
                    {...register("packageSelected", {
                      required: "This field is required.",
                    })}
                  >
                    <option value="">In which package are you interested?</option>
                    {/* <option value="Basic">Basic</option> */}
                    <option value="Open Source">Open Source</option>
                    <option value="Open Source + Hire Developer">Open Source + Hire Developer</option>
                  </Form.Select>
                  <ErrorMessage
                    errors={errors}
                    name="packageSelected"
                    render={({ message }) => (
                      <div className="invalid-feedback" id="contactpackageSelectedErr">{message}</div>
                    )}
                  />
                </Form.Group>
                
                <Form.Group className="dropdown-selector mb-4">
                  <Form.Select
                    aria-label="Default select example"
                    name="budget"
                    id="budget"
                    className={classNames("form-control", {
                      "is-invalid": errors.budget,
                    })}
                    placeholder="In which package are you interested?"
                    {...register("budget", {
                      required: "This field is required.",
                    })}
                  >
                    <option value="">Total budget*</option>
                    <option value="$3000 - $5000 Basic Solution">$3000 - $5000 Basic Solution</option>
                    <option value="$5000 - $10,000 Need a out of box solution">$5000 - $10,000 Need a out of box solution</option>
                    <option value="$10,000 - $20,000 Need a custom product">$10,000 - $20,000 Need a custom product</option>
                    <option value="Over $20,000 Enterprise platform">Over $20,000 Enterprise platform</option>
                  </Form.Select>
                  <ErrorMessage
                    errors={errors}
                    name="budget"
                    render={({ message }) => (
                      <div className="invalid-feedback" id="budgetErr">{message}</div>
                    )}
                  />
                </Form.Group>

                <Form.Group className="dropdown-selector mb-4">
                  <Form.Select
                    aria-label="Default select example"
                    name="startDate"
                    id="startDate"
                    className={classNames("form-control", {
                      "is-invalid": errors.startDate,
                    })}
                    placeholder="In which package are you interested?"
                    {...register("startDate", {
                      required: "This field is required.",
                    })}
                  >
                    <option value="">Expected project start date</option>
                    <option value="As soon as possible">As soon as possible</option>
                    <option value="Within 1 Month">Within 1 Month</option>
                    <option value="Within 3 Month">Within 3 Month</option>
                    <option value="Within 6 Month">Within 6 Month</option>
                    <option value="Within 1 Year">Within 1 Year</option>
                    <option value="Not Sure">Not Sure</option>
                  </Form.Select>
                  <ErrorMessage
                    errors={errors}
                    name="startDate"
                    render={({ message }) => (
                      <div className="invalid-feedback" id="startDateErr">{message}</div>
                    )}
                  />
                </Form.Group>

                <Form.Group
                  className="mb-4 mt-4"
                >
                  {/* <Form.Label>Message</Form.Label> */}
                  <Form.Control
                    id="txtmsg"
                    name="txtmsg"
                    as="textarea"
                    placeholder="Enter your message here"
                    rows={3}
                    className={classNames("form-control", {
                      "is-invalid": errors.txtmsg
                    })}
                    {...register("txtmsg", {
                      required: "This field is required.",
                    })}
                  />
                  <ErrorMessage
                    errors={errors}
                    name="txtmsg"
                    render={({ message }) => (
                      <div className="invalid-feedback" id="txtmsgErr">{message}</div>
                    )}
                  />
                </Form.Group>
               
                <Form.Group className="flex-prop">
                  <div className="captcha-code-wrap">
                    <LoadCanvasTemplateNoReload />
                  </div>
                  
                  <div>
                    <Form.Control
                    type="text"
                    placeholder="Enter Captcha"
                    name="contactCaptcha"
                    id="contactCaptcha"
                    className={`captcha-form ${classNames({
                      "is-invalid": errors.contactCaptcha
                    })}`}
                    {...register("contactCaptcha", {
                      required: "This field is required."
                    })}
                  />
                  <ErrorMessage
                    errors={errors}
                    name="contactCaptcha"
                    render={({ message }) => (
                      <div className="invalid-feedback" id="contact_captcha_inputErr">{message}</div>
                    )}
                  />
                  </div>
                </Form.Group>

                <Button className="form-btn mt-4" variant="primary" type="submit" disabled={loader}>
                  {loader ? 
                    <div class="spinner-border text-light spinner-border-sm p-2" />
                  :
                    <>
                       Send{" "}
                      <span className="tri-btn">
                        <FontAwesomeIcon icon={["fas", "arrow-right"]} size="lg" />
                      </span>
                    </>
                  }
                </Button>
              </Form>
            </Col>
            <Col md={5}>
              <div className="form-title">
                <h3>For Technical Support: </h3>
              </div>
              <p>
              After filling this form, Our sales specialist contact you asap.<br />

              Our specialists can demo our product and help you understand what our crowdfunding software can do for your business.<br />

              <strong>For Technical Support:</strong><br />
              Existing Customers can log in to your support account.<br />

              We have a one-time purchase plan and monthly purchase plan
              </p>
              </Col>
          </Row>
        </Container>
      </section>

      <Container className="contact-cta-wrapper  bottom-margin-80 ">
        <Row className="horizontal-center justify-between-prop">
          <Col lg={9} md={12}>
            <div className="cta-text-box">
              <h5>
                In case of any emergency, we request you to submit ticket and
                add all the server detail including FTP, Panel and other
                required information. We will try to fix the problem and also
                provide all the answers of questions asked.
              </h5>
            </div>
          </Col>

          <Col md="auto" className="contact-btn-wrapper">
            <a className="orange-btn phone-center" href="https://rockerstech.com/crm/authentication/login" target="_blank">
              Submit Ticket
              <span className="tri-btn">
                <FontAwesomeIcon icon={["fas", "arrow-right"]} size="lg" />
              </span>
            </a>
          </Col>
        </Row>
      </Container>
    </>
  );
};
