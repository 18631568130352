import React from 'react'
import FrontEndFeature from '../../img/user-support-icon.png'
import AdminFeature from '../../img/AdminSupport.png'
import DashboardImg from '../../img/DashboardImg.png'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FAiconSet } from '../FAiconSet/FAiconSet';
import { Container } from 'react-bootstrap';
import { Row, Col, } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { WebImage } from '../Image/WebImage';
import "./AdminFeatures.scss";


export const AdminFeatures = () => { 
    React.useEffect(()=>{
        window.scrollTo({top : 0, behavior:"smooth"})
    },[])
    return (
<>
    <Container>
          <div className="frontend-features-hero inner-hero-phone">
              <h1 className="vertical-center">Admin Features</h1>
          </div> 

         
          <section className="padding-y-40 bottom-margin-40">
        <Row>
              <Col md={4} className="py-3">
                    <div className="feature-li">
                        <WebImage src={AdminFeature}/>
                    
                        <h4>Dashboard</h4>
                        <p>
                        Once you log in to your admin panel, you’ll find an exclusive dashboard designed and 
                        categorized nicely. Moreover, Ticketing Software also offers statistical information on 
                        your admin data, allowing you to compare the performance of your platform.
                     </p>
                    </div>
                </Col>

                <Col md={4} className="py-3">
                    <div className="feature-li">
                        <WebImage src={AdminFeature}/>
                    
                        <h4>Admin Users</h4>
                        <p>
                        If you want to find any admin user’s details, the Admin Users section will display a list of them along with other details. You will be able to get the details of the total admin users available on the Ticketing Software.
                     </p>
                    </div>
                </Col>

                <Col md={4} className="py-3">
                    <div className="feature-li">
                        <WebImage src={AdminFeature}/>
                    
                        <h4>Users</h4>
                        <p>
                        The Users section displays the total active and non-active users on the ticketing software platform. The user's section allows you to edit, delete and view the information of any available users with an available search option. Complete user details can be viewed and manipulated through the users tab.
                     </p>
                    </div>
                </Col>
              </Row>

              <Row>
              <Col md={4} className="py-3">
                    <div className="feature-li">
                        <WebImage src={AdminFeature}/>
                    
                        <h4>Events</h4>
                        <p>
                        The Event tab contains a list of total events available on the platform. This includes live and completed events, and the admin can easily view the complete details of the event through this tab and edit the information, including a search option.

                     </p>
                    </div>
                </Col>

                <Col md={4} className="py-3">
                    <div className="feature-li">
                        <WebImage src={AdminFeature}/>
                    
                        <h4>Pages</h4>
                        <p>
                        One of Ticketing Software's innovative features is the Pages tab. It allows admins to manage and create new pages relevant to their portal. They can also manage the visibility of the completed page and update the information whenever needed. They can view the page content, edit and even delete it.
                     </p>
                    </div>
                </Col>

                <Col md={4} className="py-3">
                    <div className="feature-li">
                        <WebImage src={AdminFeature}/>
                    
                        <h4>Transactions</h4>
                        <p>
                        As the name suggests, this tab is dedicated to viewing and managing past and current transactions in the portal. You can view the transactions based on total, monthly, and yearly transactions. Complete transaction details can be viewed through the tab, solving the complexity of managing the account.
                     </p>
                    </div>
                </Col>
              </Row>

              <Row className="bottom-margin-40">
              <Col md={4} className="py-3">
                    <div className="feature-li">
                        <WebImage src={AdminFeature}/>
                    
                        <h4>Settings</h4>
                        <p>
                        Settings allow you to change the settings of different pages and applications of the Ticketing Software. If you, as an admin, feel the need to change the complete settings per your requirements or even change them per certain conditions, you are free to do so. The changes in settings cover multiple sections, including the site itself, Facebook, Twitter, PayPal, wallet, authorize settings, email templates, currency codes, tips, and all categories.
                     </p>
                    </div>
                </Col>

                <Col md={4} className="py-3">
                    <div className="feature-li">
                        <WebImage src={AdminFeature}/>
                    
                        <h4>Mange Wallet</h4>
                        <p>
                        This section displays the list of pending and confirmed withdrawals. 
                        You will be able to view the entire details of withdrawals that took place 
                        or withdrawals that are set to take place in your portal.
                     </p>
                    </div>
                </Col>

                <Col md={4} className="py-3">
                    <div className="feature-li">
                        <WebImage src={AdminFeature}/>
                    
                        <h4>Cancelled Orders</h4>
                        <p>
                        This section displays the total canceled events on your platform. You can view all the details through this tab.
                        </p>
                    </div>
                </Col>
              </Row>
              {/* <div className="feature-cta">
                     <Link className="blue-btn" to="/admin-features">Features
                     
                     <span className="tri-btn">
                     <FontAwesomeIcon icon={['fas', 'arrow-right']} size="lg" />
                     </span>
                     
                     </Link>
                     </div>  */}
        </section>  
          
    </Container>      
</>
)
}