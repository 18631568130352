import React, { Component, useState, useRef } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import { Row, Col } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import BlogImage1 from '../../../img/blog-img-1.jpg';
import BlogImage2 from '../../../img/blog-img-2.jpg';
import BlogImage3 from '../../../img/blog-img-3.jpg';

function BlogNextArrow(props) {
    const { onClick } = props;
    return (
        <div
            className="next-btn"
            onClick={onClick}
        >
         <FontAwesomeIcon icon={['fas', 'caret-left']}/>
    </div>
    );
  }
  
  function BlogPrevArrow(props) {
    const { onClick } = props;
    return (
      <div
        className="previous-btn"
        onClick={onClick}
     >
         <FontAwesomeIcon icon={['fas', 'caret-right']}/>
    </div>
    );
  }

export const BlogSection = () => {
    const [slider, setSlider] = useState({
        slideIndex: 0,
        updateCount: 0
    })
    const sliderRef = useRef();
    const settings = {
        dots: false,
        arrows: true,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        nextArrow: <BlogNextArrow />,
        prevArrow: <BlogPrevArrow />,
        responsive: [
            {
                breakpoint: 821,
                settings: {
                  slidesToShow: 2,
                }
            },
            {
              breakpoint: 600,
              settings: {
                slidesToShow: 1,
              }
            }
          ]    
    };
    return (
        <>
            <div className="blog-cards-wrapper">
                <Slider ref={sliderRef => (sliderRef)} {...settings}>
                <div className="blog-item">
                    <div className="blog-clm">
                        <div className="blog-img"><img src={BlogImage1} alt="blog 1" /></div>
                        <div className="blog-body">
                            <h4>How facebook can help?</h4>
                            <p>Learning about various types of fund raising activities will help you benefit from raising funds for</p>
                            <div className="blog-btn-div">
                                <Link className="phone-center orange-btn" href="#" to="/facebook-can-help-success-event">Read More
                                    <span className="tri-btn">
                                        <FontAwesomeIcon icon={['fas', 'arrow-right']} size="lg" />
                                    </span>
                                </Link>
                            </div>
                        </div>
                    </div>
                    </div>
                    <div className="blog-item">
                    <div className="blog-clm">
                        <div className="blog-img"><img src={BlogImage2} alt="blog 2" /></div>
                        <div className="blog-body">
                            <h4>Major Role of Social Media</h4>
                            <p>Event can be successful only when they are properly advertised and promoted through suitable medium.</p>
                            <div className="blog-btn-div">
                                <Link className="phone-center orange-btn" href="#" to="/major-role-social-media">Read More
                                    <Link className="tri-btn" to="#">
                                        <FontAwesomeIcon icon={['fas', 'arrow-right']} size="lg" />
                                    </Link>
                                </Link>
                            </div>
                        </div>
                    </div>
                    </div>
                    <div className="blog-item">
                    <div className="blog-clm">
                        <div className="blog-img"><img src={BlogImage3} alt="blog 3" /></div>
                        <div className="blog-body">
                            <h4>12 Excellent Startup Ideas</h4>
                            <p>Think as an entrepreneur you have an idea with a passion to change the world and you want to turn it up</p>
                            <div className="blog-btn-div">
                                <Link className="phone-center orange-btn" href="#" to="/startup-ideas">Read More
                                    <Link className="tri-btn" to="#">
                                        <FontAwesomeIcon icon={['fas', 'arrow-right']} size="lg" />
                                    </Link>
                                </Link>
                            </div>
                        </div>
                    </div>
                    </div>
                </Slider>
            </div>
        </>
    )
}