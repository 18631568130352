import React from 'react';
import LogoFooter from '../../img/logo-footer.png'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Col, Container, Row, Nav } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { Form } from 'react-bootstrap';
import { WebImage } from '../Image/WebImage';
import "./Footer.scss"


export const Footer = () => {
    return (
  <>
    <section className="footer-section">
        <Container>
            <Row className="g-3">
            <Col md={3}>
            <div>
                <WebImage src={LogoFooter} alt="ticketing softwares"/>
                <p className="max-width-250">
                    With a dream to caters the event industry with highly-technical and 
                    advanced resources, we have developed Ticketing Software.
                </p>
            </div>
            
         </Col>

         <Col md={3} className="footer-link-wrapper">
         <h4>Quick Links</h4>
         <ul>
             <Link className="footer-menu-link" to="/Support"><li>Support</li></Link>
             <Link className="footer-menu-link" to="/contact"><li>Contact Us</li></Link>
             <Link className="footer-menu-link" to="/sale-question"><li>Pre-sales Question</li></Link>
             <Link className="footer-menu-link" to="/delivery-policy"><li>Delivery Policy</li></Link>
             <Link className="footer-menu-link" to="/server-requirements"><li>Server Requirements</li></Link>
         </ul>
         </Col>

         <Col md={3} className="footer-link-wrapper">
         <h4>Useful Links</h4>
         <ul>
             <Link className="footer-menu-link" to="/about"><li>About</li></Link>
             <Link className="footer-menu-link" to="/blog"><li>Blog</li></Link>
             {/* <Link className="footer-menu-link" to="/faqs"><li>FAQs</li></Link> */}
             <Link className="footer-menu-link" to="/terms-and-condition"><li>Terms and conditions</li></Link>
             <Link className="footer-menu-link" to="/privacy-policy"><li>Privacy Policy</li></Link>
         </ul>
         </Col>

         <Col md={3} className="footer-contact-wrapper">
         <h4>Connect With Us</h4>
         <ul>
             <Nav.Link className="footer-co-link" href="tel:+91 787-433-0444" to="#">+91 940-828-8843</Nav.Link>
             <li className="max-width-250">306- 3rd Floor- Corner Heights,
                Near Delhi Public School, Ring Road Kalali,
                Vadodara- 390012, Gujarat, India</li>
         </ul>

         <div className="footer-social-media-wrapper"> 
            <Nav.Link className="fo-social-icon" href="https://www.facebook.com/ticketingsoftware/" target="_blank">      
            <FontAwesomeIcon icon={['fab', 'facebook-f']} size="lg"/>
            </Nav.Link>
            <Nav.Link className="fo-social-icon" href="https://twitter.com/Ticketingsoft" target="_blank"> 
            <FontAwesomeIcon icon={['fab', 'twitter']} size="lg"/>
            </Nav.Link>
            <Nav.Link className="fo-social-icon" href="https://www.linkedin.com/in/ticketing-softwares-949728ba/" target="_blank"> 
            <FontAwesomeIcon icon={['fab', 'linkedin-in']} size="lg"/>
            </Nav.Link>
            <Nav.Link className="fo-social-icon" href="https://www.youtube.com/channel/UCVX7nF97o_UQ1bguY-0DNLw" target="_blank"> 
            <FontAwesomeIcon icon={['fab', 'youtube']} size="lg"/>
            </Nav.Link>
            </div>
            <Form className="footer-language_selector">
            <Form.Group as={Col} controlId="formGridState">
           <Form.Select defaultValue="Select Language">
           <option>Select Language</option>
           <option>Arebic</option>
           <option>American</option>
           <option>Bulgerian</option>
           <option>Catalan</option>
          </Form.Select>
         </Form.Group>
            </Form>

         </Col>
         </Row>
        </Container>
    </section>

  </>
)
}