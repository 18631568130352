import React, {useEffect} from 'react'
import {Row, Col, Container } from 'react-bootstrap';
import "../../componants/Support/Support.scss";

export const Support = () => {
    useEffect(() => {
        window.scrollTo({ top: 0, behavior: "smooth" });
      }, [])

    return (   
    <>
    <Container>
    <div className="support-hero inner-hero-phone">
        <h1 className="vertical-center">Support</h1>
        
    </div>
        <Row className="padding-y-40 no-padding-phone align-items-center paragraph-wrpr-row side-pattern">
        <Col md={12} className="top-space bottom-space-40">
            <div className="li-wrapper">
            <p>
            <p>Ticketing Software needs to be your go-to if you are looking for a supportive and responsive 
                website that keeps in touch with its clients. At Ticketing Software, we ensure that we 
                remain in contact with our clients in every way possible.</p>
            <p className='blogtop-margin-30'>We have ways of ensuring that you get the solutions to your problems 
            and the answers to your queries without issues. Our team responds to the questions within 24 
            working days, Monday through Friday.</p>
            <p className='blogtop-margin-30'>If there is any kind of emergency, we request that you submit a 
            ticket and include all the server details like  FTP, Panel, and other required information. 
            You can also provide snapshots and try to explain your problems through screenshots. We will 
            try our best to give you a detailed explanation and solution to your problems. We also allow 
            you to speak to a decision-maker or manager if necessary.</p>
            </p>
            </div>
        </Col>
        </Row>
     </Container>
    </>
     )
}