import React, {useState, useEffect} from 'react';
import { Helmet } from "react-helmet";
import SupportIcon from '../../img/support-icon.png'
import FeaturesIcons from '../../img/features-icon.png'
import OnlineTicketing from '../../img/online-ticketing.png'
import bookEventTicket from '../../img/book-event-img.png'
import OrganiserApp from '../../img/organiser-app.png'
import SeatingMap from '../../img/seating-map.png'
import OfferIcon from '../../img/offer-icon.png'
import { Row, Col, Container, Carousel } from 'react-bootstrap';
import bannerOne from '../../img/banner-1.png';
import bannerTwo from '../../img/banner-2.png';
import { Link } from 'react-router-dom';
import { useSpring, animated } from 'react-spring';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { BlogSection } from '../Home/BlogSection/BlogSection';
import { WebImage } from '../Image/WebImage';
import  { sampleDataList } from './testimonyData';
// import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;600;700;800;900&display=swap');
import "./Home.scss";
import "../../styles/style.scss";

export const Home = () => {
  const products = useSpring({ val: 150, from: { val: 0 } });
  const client = useSpring({ val: 250, from: { val: 0 } });
  const [selectedTestimonialKey, setSelectedTestimonialKey] = useState(0);
  const [selectedTestimonial, setSelectedTestimonial] = useState();
  const [showMore, setShowMore] = useState(1);
  const showLimit = 5;
  const firstItem = (showMore * showLimit) - showLimit;
  const lastItem = showMore * showLimit;

  useEffect(() => {
    setSelectedTestimonialKey(lastItem - showLimit);
    setSelectedTestimonial(sampleDataList?.find((item, key) => key === lastItem - showLimit));
  }, [showMore]);

  const testimonialsList = sampleDataList?.map((item, key) => (
     key >= firstItem && key < lastItem ?
      <div className={`avatar ${selectedTestimonialKey === key ? "active" : ""}`} key={key}>
        <div
          className="avatar-img-wrapper"
          onClick={() => {
            setSelectedTestimonialKey(key);
            setSelectedTestimonial(item);
          }}
        >
          <WebImage className="avatar-img" src={item?.image} alt={`image${key}`} />
        </div>
        <div className="avtar-caption">
          <h4>{item?.name}</h4>
          <p>{item?.role}</p>
        </div>
      </div>
      : null
  ));

  document.title = "About TicketingSoftwares Online Ticketing Software System";
  document.querySelector('meta[name="description"]').setAttribute(
    "content",
    "Know more about TicketingSoftwares online ticketing system software and start your own event platform like eventbrite."
  );


  return (
    <>
      <Container id="main-container">
        <div className="home-carosel">
          <Carousel fade>
            <Carousel.Item>
              <WebImage
                className="d-block w-100 slide-img"
                src={bannerOne}
                alt="First slide"
              />
              <Carousel.Caption className="slider-caption">
                <h1>Awe your Audience</h1>
                <p>Discover events and never miss out on a full house!</p>
                <Link className="blue-btn" to="/contact">
                  Contact Today
                  <span className="tri-btn">
                    <FontAwesomeIcon icon={['fas', 'arrow-right']} size="lg" />
                  </span>
                </Link>
              </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item>
              <WebImage
                className="d-block w-100 slide-img"
                src={bannerTwo}
                alt="Second slide"
              />
              <Carousel.Caption className="slider-caption">
                <h1>Every feature in the palms of your hands</h1>
                <p>Create and manage your events, reach out to an audience, and earn a profit easier than ever while working with Ticketing Software.</p>
                <Link className="blue-btn" to="/contact">
                  Contact Today
                  <span className="tri-btn">
                    <FontAwesomeIcon icon={['fas', 'arrow-right']} size="lg" />
                  </span>
                </Link>
              </Carousel.Caption>
            </Carousel.Item>
          </Carousel>
          <div className="anim-number-wrapper anim_banner_box">
            <div className="anim-number-boxs">
              <div className="number-box px-3">
                <animated.div className="digits">
                  {products.val.interpolate(val => Math.floor(val))}
                </animated.div>
                <div className="caption">Products</div>
              </div>
              <div className="number-box px-3">
                <animated.div className="digits">
                  {client.val.interpolate(val => Math.floor(val))}
                </animated.div>
                <div className="caption">Happy Client</div>
              </div>
              {/* <div className="round-btn-section">
                        <div className="round-btn">
                        <Link to="#">
                            <FontAwesomeIcon icon={['fas', 'arrow-right']}/>
                            </Link>
                            </div> 
                        </div> */}
              <div className="number-line" />
            </div>
          </div>
        </div>
        <section className="padding-y-80">
          <Row>
            <Col xs={12} sm={12} md={4} className="py-3">
              <div className="first-clm">
                <div className="clm-img"><WebImage src={OfferIcon} alt="what we offer" /></div>
                <div className="clm-body">
                  <h3>What We Offer</h3>
                  <p>Ticketing Software allows users to try out demos and ask pre-sale questions before they purchase the offered services.</p>
                </div>
              </div>
              <div className="clm-btn-div">
                <Link className="orange-btn w-100 clm-btn" to="/contact">Contact Today
                  <span className="tri-btn">
                    <FontAwesomeIcon icon={['fas', 'arrow-right']} size="lg" />
                  </span>
                </Link>
              </div>
            </Col>
            <Col xs={12} sm={12} md={4} className="py-3">
              <div className="first-clm">
                <div className="clm-img"><WebImage src={SupportIcon} alt="support" /></div>
                <div className="clm-body">
                  <h3>Our Support</h3>
                  <p>Ticketing Software offers event organizers a platform to learn how to sell event tickets easily.</p>
                </div>
              </div>
              <div className="clm-btn-div">
                <Link className="orange-btn w-100 clm-btn" to="/blog">Blog
                  <span className="tri-btn">
                    <FontAwesomeIcon icon={['fas', 'arrow-right']} size="lg" />
                  </span>
                </Link>
              </div>
            </Col>
            <Col xs={12} sm={12} md={4} className="py-3">
              <div className="first-clm">
                <div className="clm-img"><WebImage src={FeaturesIcons} alt="features" /></div>
                <div className="clm-body">
                  <h3>Features & Quality</h3>
                  <p>Ticketing Software has two built-in features: User Support and Admin Support.</p>
                </div>
              </div>
              <div className="clm-btn-div">
                <Link className="orange-btn w-100 clm-btn" to="/features">Know More
                  <span className="tri-btn">
                    <FontAwesomeIcon icon={['fas', 'arrow-right']} size="lg" />
                  </span>
                </Link>
              </div>
            </Col>
          </Row>
        </section>
      </Container>
      <section className="colored-bg padding-y-80">
        <Container>
          <Row className="align-items-center paragraph-wrpr-row">
            <Col xs={12} md={5} className="order-md-2 order-sm-1">
              <div className="right-img">
                <WebImage src={OnlineTicketing} alt="take demo" />
              </div>
              <Link className="btn-on-img align-items-center" to="/demo">
                Take a Look On Demo
                <div className="demo-btn">
                  <div className="arrow">
                    <span className="triangle-btn">
                      <FontAwesomeIcon icon={['fas', 'arrow-right']} size="lg" />
                    </span>
                  </div>
                </div>
              </Link>
            </Col>
            <Col xs={12} md={7} className="order-md-1 order-sm-2">
              <div className="paragraph-wrapper">
                <h2>
                  <span className="font-light">Online</span>
                  <span className="orange-text"> Ticketing System Software</span>
                </h2>
                <p>
                Have an event coming up and have tickets you want to sell? We can help.</p> 
                <p>
                With a dream to cater to the event industry with highly-technical and advanced 
                resources, we come to you with an online ticketing system software. As a multi-faceted 
                event management platform, we aim to have visitors and users interested in posting and 
                creating buzz about events.
                </p>
                <div>
                  <Link className="phone-center orange-btn mt-2" to="/contact">Buy Now
                    <span className="tri-btn">
                      <FontAwesomeIcon icon={['fas', 'arrow-right']} size="lg" />
                    </span>
                  </Link>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <Container>
        <section className="padding-y-40">
          <Row className="align-items-center paragraph-wrpr-row">
            <Col xs={12} md={5}>
              <div className="left-img">
                <WebImage src={SeatingMap} alt="venue map" />
              </div>
            </Col>
            <Col xs={12} md={7} className="order-2-md">
              <div className="paragraph-wrapper">
                <h2>
                  <span className="font-light">Build Your</span>
                  <span className="orange-text"> Venue Map</span>
                </h2>
                <p>
                  Our Venue Maps are more than just visual layouts
                  of a venue. They’re clickable, shoppable floor plans,
                  so attendees can easily buy the right tickets for
                  their event experience.
                </p>
                <div>
                  <Link className="phone-center orange-btn mt-2" to="/demo">Check Demo
                    <span className="tri-btn">
                      <FontAwesomeIcon icon={['fas', 'arrow-right']} size="lg" />
                    </span>
                  </Link>
                </div>
              </div>
            </Col>
          </Row>
        </section>
        <section>
          <Row className="padding-y-40 align-items-center paragraph-wrpr-row">
            <Col xs={12} md={5} className="order-md-2 order-sm-1">
              <div className="left-img">
                <WebImage src={bookEventTicket} alt="event creation tools" />
              </div>
            </Col>
            <Col xs={12} md={7} className="order-md-1 order-sm-2">
              <div className="paragraph-wrapper padding-right">
                <h2>
                  <span className="font-light">Event Creation And</span>
                  <span className="orange-text"> Ticketing Tools</span>
                </h2>
                <p>
                Ticketing Software provides every tool to set up and sell tickets for virtual 
                and in-person events.
                </p>
                <div>
                  {/* <Link className="phone-center orange-btn mt-2" to="/contact">Buy Now
                    <span className="tri-btn">
                      <FontAwesomeIcon icon={['fas', 'arrow-right']} size="lg" />
                    </span>
                  </Link> */}
                </div>
              </div>
            </Col>
          </Row>
        </section>
        <section className="padding-y-40">
          <Row className="paragraph-wrpr-row align-items-center">
            <Col md={5}>
              <div className="left-img">
                <WebImage src={OrganiserApp} alt="Organize App" />
              </div>
            </Col>
            <Col md={7} >
              <div className="paragraph-wrapper">
                <h2>
                  <span className="font-light">Organize</span>
                  <span className="orange-text"> App</span>
                </h2>
                <p>
                With clickable and shoppable floor plans, our Venue Maps exceed the next level, 
                giving attendees the platform to buy tickets easily.
                </p>
                <div>
                  <Link className="phone-center orange-btn mt-2" to="/demo/#mobileApp-demo">Mobile App Demo
                    <span className="tri-btn">
                      <FontAwesomeIcon icon={['fas', 'arrow-right']} size="lg" />
                    </span>
                  </Link>
                </div>
              </div>
            </Col>
          </Row>
        </section>
        {/* <section className="bottom-padding">
          <Row>
            <Col md={8}>
              <div className="testimo-graphic"></div>
              <div className="testimo-bg">
                <div className="testimo-text">
                  <p>
                    {selectedTestimonial?.testimonyMsg}
                  </p>
                </div>
              </div>
              <div className="thumbnails-wapper">
                {testimonialsList}
                <button
                  className="testi-btn-pre testi-btn"
                  disabled={showMore === 1}
                  onClick={() => {
                    setShowMore(showMore - 1);
                  }}
                >
                  <FontAwesomeIcon icon={['fas', 'caret-left']} />
                </button>
                <button
                  className="testi-btn"
                  disabled={lastItem >= sampleDataList?.length}
                  onClick={() => {
                    setShowMore(showMore + 1);
                  }}
                >
                  <FontAwesomeIcon icon={['fas', 'caret-right']} />
                </button>
              </div>
            </Col>
          </Row>
        </section> */}
      </Container>
      <section className="blog-top-space">
        <div className="blog-title">
          <h2>
            <span className="font-light">Latest</span>
            <span className="orange-text"> Blog</span>
          </h2>
        </div>
        <Container className="blog-container">
          <BlogSection />
        </Container>
      </section>
    </>
  )
}
