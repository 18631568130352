import React, {useEffect } from "react";
import { Helmet } from "react-helmet";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Row, Col, Container } from "react-bootstrap";
import { Link } from "react-router-dom";
import BlogImage1 from '../../img/blog-img-1.jpg';
import BlogImage2 from '../../img/blog-img-2.jpg';
import BlogImage3 from '../../img/blog-img-3.jpg';
import "./Blog.scss";
import "../../styles/style.scss";

export const Blog = () => {
    useEffect(() => {
        window.scrollTo({ top: 0, behavior: "smooth" });
      }, [])
      document.title = "Ticketing Softwares Blog, Latest Updates, Userful Information, Tips & Tricks";
      document.querySelector('meta[name="description"]').setAttribute(
        "content",
        "Checkout TicketingSoftwares.com Blog for Latest and Useful Updates/Information"
    );
    return (   
    <>
        <Container>
            <div className="blog-hero inner-hero-phone">
                <h1 className="vertical-center">Blog</h1>
            </div>
        
            <Row className="padding-y-80"> 
                <Col md={4} className="blog-wrapper">
                <div className="blog-clm">
                    <div className="blog-img"><img src={BlogImage1} alt="blog 1" /></div>
                    <div className="blog-body">
                        <h4>How Facebook can help you for Success of Event?</h4>
                        <p>Facebook is dynamic platform to endorse your product, business, services and events.</p>
                        <div className="blog-btn-div">
                            <Link className="phone-center orange-btn" to="/facebook-can-help-success-event">Continue reading
                                <span className="tri-btn">
                                    <FontAwesomeIcon icon={['fas', 'arrow-right']} size="lg" />
                                </span>
                            </Link>
                        </div>
                    </div>
                </div>
                </Col>
                            
                <Col md={4} className="blog-wrapper">
                <div className="blog-clm">
                    <div className="blog-img"><img src={BlogImage2} alt="blog 2" /></div>
                    <div className="blog-body">
                        <h4>Major Role of Social Media for Event Success</h4>
                        <p>Event can be successful only when they are properly advertised and promoted through suitable medium. </p>
                        <div className="blog-btn-div">
                            <Link className="phone-center orange-btn" to="/major-role-social-media">Continue reading
                                <Link className="tri-btn" to="#">
                                    <FontAwesomeIcon icon={['fas', 'arrow-right']} size="lg" />
                                </Link>
                            </Link>
                        </div>
                    </div>
                </div>
                </Col>
                            
                <Col md={4} className="blog-wrapper">
                <div className="blog-clm">
                    <div className="blog-img"><img src={BlogImage3} alt="blog 3" /></div>
                    <div className="blog-body">
                        <h4>12 Excellent Startup Ideas</h4>
                        <p>Think as an entrepreneur you have an idea with a passion to change the world and you want to turn it up in to a startup business </p>
                        <div className="blog-btn-div">
                            <Link className="phone-center orange-btn" to="/startup-ideas">Continue reading
                                <Link className="tri-btn" to="#">
                                    <FontAwesomeIcon icon={['fas', 'arrow-right']} size="lg" />
                                </Link>
                            </Link>
                        </div>
                    </div>
                </div>
                </Col>   
            </Row> 
        
            
        </Container>
    </>
     )
}