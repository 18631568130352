import React from 'react'
import FrontEndFeature from '../../img/user-support-icon.png'
import AdminFeature from '../../img/AdminSupport.png'
import DashboardImg from '../../img/DashboardImg.png'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FAiconSet } from '../FAiconSet/FAiconSet';
import { Container } from 'react-bootstrap';
import { Row, Col, } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { WebImage } from '../Image/WebImage';
import "./Buyer.scss";


export const Buyer = () => { 

    return (
<>
    <Container>
          <div className="frontend-features-hero">
              <h1 className="vertical-center">Buyer</h1>
          </div> 

          <section className="padding-y-40 bottom-margin-40">

        <Row>
              <Col md={4} className="py-3">
                    <div className="feature-li">
                        <WebImage src={AdminFeature}/>
                    
                        <h4>Advanced Dashboard</h4>
                        <p>
                        At vero eos et accusamus et iusto odio dignissimos ducimus 
                        qui blanditiis praesentium voluptatum deleniti atque corrupti 
                        quos dolores et quas molestias excepturi sint occaecati cupiditate.
                     </p>
                    </div>
                </Col>

                <Col md={4} className="py-3">
                    <div className="feature-li">
                        <WebImage src={AdminFeature}/>
                    
                        <h4>Social Media Support</h4>
                        <p>
                        At vero eos et accusamus et iusto odio dignissimos ducimus 
                        qui blanditiis praesentium voluptatum deleniti atque corrupti 
                        quos dolores et quas molestias excepturi sint occaecati cupiditate.
                     </p>
                    </div>
                </Col>

                <Col md={4} className="py-3">
                    <div className="feature-li">
                        <WebImage src={AdminFeature}/>
                    
                        <h4>Dynamic Event Information Form</h4>
                        <p>
                        At vero eos et accusamus et iusto odio dignissimos ducimus 
                        qui blanditiis praesentium voluptatum deleniti atque corrupti 
                        quos dolores et quas molestias excepturi sint occaecati cupiditate.
                     </p>
                    </div>
                </Col>
              </Row>

              <Row>
              <Col md={4} className="py-3">
                    <div className="feature-li">
                        <WebImage src={AdminFeature}/>
                    
                        <h4>Multiple Tabs</h4>
                        <p>
                        At vero eos et accusamus et iusto odio dignissimos ducimus 
                        qui blanditiis praesentium voluptatum deleniti atque corrupti 
                        quos dolores et quas molestias excepturi sint occaecati cupiditate.
                     </p>
                    </div>
                </Col>

                <Col md={4} className="py-3">
                    <div className="feature-li">
                        <WebImage src={AdminFeature}/>
                    
                        <h4>Search Tab</h4>
                        <p>
                        At vero eos et accusamus et iusto odio dignissimos ducimus 
                        qui blanditiis praesentium voluptatum deleniti atque corrupti 
                        quos dolores et quas molestias excepturi sint occaecati cupiditate.
                     </p>
                    </div>
                </Col>

                <Col md={4} className="py-3">
                    <div className="feature-li">
                        <WebImage src={AdminFeature}/>
                    
                        <h4>Design</h4>
                        <p>
                        At vero eos et accusamus et iusto odio dignissimos ducimus 
                        qui blanditiis praesentium voluptatum deleniti atque corrupti 
                        quos dolores et quas molestias excepturi sint occaecati cupiditate.
                     </p>
                    </div>
                </Col>
              </Row>

              <Row>
              <Col md={4} className="py-3">
                    <div className="feature-li">
                        <WebImage src={AdminFeature}/>
                    
                        <h4>Account Tab</h4>
                        <p>
                        At vero eos et accusamus et iusto odio dignissimos ducimus 
                        qui blanditiis praesentium voluptatum deleniti atque corrupti 
                        quos dolores et quas molestias excepturi sint occaecati cupiditate.
                     </p>
                    </div>
                </Col>

                <Col md={4} className="py-3">
                    <div className="feature-li">
                        <WebImage src={AdminFeature}/>
                    
                        <h4>Contacts List</h4>
                        <p>
                        At vero eos et accusamus et iusto odio dignissimos ducimus 
                        qui blanditiis praesentium voluptatum deleniti atque corrupti 
                        quos dolores et quas molestias excepturi sint occaecati cupiditate.
                     </p>
                    </div>
                </Col>

                <Col md={4} className="py-3">
                    <div className="feature-li">
                        <WebImage src={AdminFeature}/>
                    
                        <h4>Profile</h4>
                        <p>
                        At vero eos et accusamus et iusto odio dignissimos ducimus 
                        qui blanditiis praesentium voluptatum deleniti atque corrupti 
                        quos dolores et quas molestias excepturi sint occaecati cupiditate.
                     </p>
                    </div>
                </Col>
              </Row>

              <Row className="bottom-margin-40">
              <Col md={4} className="py-3">
                    <div className="feature-li">
                        <WebImage src={AdminFeature}/>
                    
                        <h4>Help Centre</h4>
                        <p>
                        At vero eos et accusamus et iusto odio dignissimos ducimus 
                        qui blanditiis praesentium voluptatum deleniti atque corrupti 
                        quos dolores et quas molestias excepturi sint occaecati cupiditate.
                     </p>
                    </div>
                </Col>

                <Col md={4} className="py-3">
                    <div className="feature-li">
                        <WebImage src={AdminFeature}/>
                    
                        <h4>Responsive Design</h4>
                        <p>
                        At vero eos et accusamus et iusto odio dignissimos ducimus 
                        qui blanditiis praesentium voluptatum deleniti atque corrupti 
                        quos dolores et quas molestias excepturi sint occaecati cupiditate.
                        
                     </p>
                    </div>
                </Col>

                <Col md={4} className="py-3">
                    <div className="feature-li">
                        <WebImage src={AdminFeature}/>
                    
                        <h4>Seating Map</h4>
                        <p>
                        At vero eos et accusamus et iusto odio dignissimos ducimus 
                        qui blanditiis praesentium voluptatum deleniti atque corrupti 
                        quos dolores et quas molestias excepturi sint occaecati cupiditate.
                     </p>
                    </div>
                </Col>
              </Row>
              {/* <div className="feature-cta">
                     <Link className="blue-cta" to="/admin-features">Features
                     
                     <span className="tri-btn">
                     <FontAwesomeIcon icon={['fas', 'caret-right']} size="lg"/>
                     </span>
                     
                     </Link>
                     </div> */}
        </section>  
          
            
    
    </Container>
</>
)
}