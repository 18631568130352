import React, {useEffect} from 'react'
import { Helmet } from "react-helmet";
import FrontEndFeature from '../../img/user-support-icon.png'
import AdminFeature from '../../img/AdminSupport.png'
import DashboardImg from '../../img/DashboardImg.png'
import FrontEndDImg from '../../img/frontEndDImg.png'
import EventFormImg from '../../img/event-form.png'
import AdminUser from '../../img/admin-user.png'
import UserImage from '../../img/active-non-active-user.png'
import SocialMediaSupport from '../../img/social-meida-img.png'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Container } from 'react-bootstrap';
import { Row, Col, } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { WebImage } from '../Image/WebImage';
import "./Features.scss";

export const Features = () => { 
    useEffect(() => {
        window.scrollTo({ top: 0, behavior: "smooth" });
      }, [])

    document.title = "Eventbrite Clone Feature List, Features Ticketing Softwares Script, Ticketing Software";
    document.querySelector('meta[name="description"]').setAttribute(
        "content",
        "Checkout the features of TicketingSoftwares/Eventbrite Clone. Buy TicketingSoftwares Script now and give wings to your online business ideas."
    );
    return (
        <>
            <Container>
                    <div className="features-hero inner-hero-phone">
                        <h1 className="vertical-center">Features</h1>
                    </div> 

                    <section className="margin-top-80">
                    <div className="center-heading">
                            <h2>
                            <span className="font-light">Built-In Features of Our</span> 
                            <span className="orange-text"> Event Ticketing Software</span>
                            </h2>
                            <h5>
                                At Ticketing Software, we have an event ticketing system software 
                                that uses its online space to provide a platform for event organizers 
                                where they can sell event tickets. Two built-in features of our Ticketing 
                                Software support system include User Support and Admin Support. <br />
                                <strong>These two categories are divided into even more categories which include:</strong>
                            </h5>
                            </div>
                        <Row className="fe-main-category">
                        
                            <Col md={6} className="py-3" >
                                <div className="feature-card">
                                <div className="feature-content-wrapper" onClick={() => window.location.replace("#organized-features")}>
                                <WebImage src={FrontEndFeature}/>
                                <h4>
                                Organizer Features
                                </h4>
                                </div>
                                </div>
                            </Col>
                        

                            <Col md={6} className="py-3">
                                <div className="feature-card">
                                <div className="feature-content-wrapper" onClick={() => window.location.replace("#admin-features")}>
                                <WebImage src={AdminFeature}/>
                                <h4>Admin Feature</h4>
                                </div>
                                </div>
                            </Col>
                        
                                <div className="feature-cat-wrapper">
                                    <h4>
                                    These two categories are further subdivided into other categories as follows
                                    </h4>
                                </div>
                        </Row>
                    </section> 

                    <section className="padding-y-40 features_li_phn_wrapper" id="organized-features">
                    <div className="center-heading">
                            <h2>
                            <span className="orange-text">Organizer</span> 
                            <span className="font-light"> Features</span>
                            </h2>
                            </div>

                            <Row className="padding-y-40 align-items-center paragraph-wrpr-row no-phn-pt">
                            <Col xs={12} md={5} className="order-md-2 order-sm-1">
                            <div className="right-img">
                                <WebImage src={FrontEndDImg}/>      
                            </div>
                            </Col>
                            <Col xs={12} md={7} className="order-md-1 order-sm-2">
                            <div>
                                <h3>
                                Advanced Dashboard
                                </h3>
                                <p>
                                The dashboard page allows new users and visitors to see all the information. 
                                The advanced dashboard at Ticketing Software is organized into categories for 
                                ease of use so that users can easily use the portal to maintain their accounts. 
                                You can visit any page directly through the dashboard and update the information. 
                                The portal allows flexibility to customize the look of the dashboard to fit your requirements.
                                </p>
                    
                            </div>
                            </Col>

                        </Row>

                        <Row className="padding-y-40 align-items-center paragraph-wrpr-row">
                            <Col xs={12} md={5}>
                            <div className="left-img">
                            <WebImage src={SocialMediaSupport}/>
                            </div>
                            </Col>

                            <Col xs={12} md={7}>
                            <div>
                                <h3>
                                Social Media Support
                                </h3>
                                <p>
                                Our event ticketing system software provides better connectivity with the leading social media platforms. Users can promote their posts on social media sites through our ticketing software and invite friends of friends to visit their page.
                                </p>
                            </div>
                            </Col>
                        </Row>

                        
                        <Row className="padding-y-40 align-items-center paragraph-wrpr-row">
                        
                            <Col xs={12} md={5} className="order-md-2 order-sm-1">
                            <div className="right-img">
                                <WebImage src={EventFormImg}/>      
                            </div>
                            </Col>
                            <Col xs={12} md={7} className="order-md-1 order-sm-2">
                            <div>
                                <h3>
                                Dynamic Event Information Form
                                </h3>
                                <p>
                                The event ticketing system software submission form is divided into three categories: 
                                Add Your Event Details, Create Tickets, and Promote Your Event. The whole event form 
                                page is designed keeping the user in mind so that the user can easily explain their 
                                event. In addition, the form also supports audio-video and image files.
                                </p>
                                
                            </div>
                            </Col> 
                        </Row>
                        
                        <div className="feature-cta">
                                <Link className="blue-btn" to="/front-end-features">View More
                                
                                <span className="tri-btn">
                                <FontAwesomeIcon icon={['fas', 'arrow-right']} size="lg"/>
                                </span>
                                
                                </Link>
                                </div> 
                    </section>

                    <section className="padding-y-40 features_li_phn_wrapper" id="admin-features">
                    <div className="center-heading">
                            <h2>
                            <span className="orange-text">Admin</span> 
                            <span className="font-light"> Features</span>
                            </h2>
                            </div>

                            <Row className="padding-y-40 align-items-center paragraph-wrpr-row no-phn-pt">
                            <Col xs={12} md={5} className="order-md-2 order-sm-1">
                            <div className="right-img">
                                <WebImage src={DashboardImg}/>      
                            </div>
                            </Col>
                            <Col xs={12} md={7} className="order-md-1 order-sm-2">
                            <div>
                                <h3>
                                Dashboard
                                </h3>
                                <p>
                                Once you log in to your admin panel, you’ll find an exclusive dashboard designed 
                                and categorized nicely. Moreover, Ticketing Software also offers statistical 
                                information on your admin data, allowing you to compare the performance of your platform.
                                </p>
                                
                            </div>
                            </Col>
                            

                        </Row>

                        <Row className="padding-y-40 align-items-center paragraph-wrpr-row">
                            <Col xs={12} md={5}>
                            <div className="left-img">
                            <WebImage src={AdminUser}/>
                            </div>
                            </Col>

                            <Col xs={12} md={7}>
                            <div>
                                <h3>
                                Admin Users
                                </h3>
                                <p>
                                If you want to find any admin user’s details, the Admin Users section will display a list of them along with other details. You will be able to get the details of the total admin users available on the Ticketing Software.
                                </p>
                            </div>
                            </Col>
                        </Row>

                        
                        <Row className="padding-y-40 align-items-center paragraph-wrpr-row">
                        <Col xs={12} md={5} className="order-md-2 order-sm-1">
                            <div className="right-img">
                                <WebImage src={UserImage}/>      
                            </div>
                            </Col>
                            <Col xs={12} md={7} className="order-md-1 order-sm-2">
                            <div>
                                <h3>
                                Users
                                </h3>
                                <p>
                                The Users section displays the total active and non-active users on the ticketing 
                                software platform. The user's section allows you to edit, delete and view the 
                                information of any available users with an available search option. Complete user 
                                details can be viewed and manipulated through the users tab.
                                </p>
                            
                            </div>
                            </Col>
                            
                        </Row>
                        <Row className="bottom-margin-40">
                            <div className="feature-cta">
                                <Link className="blue-btn" to="/admin-features">View More
                                
                                <span className="tri-btn">
                                <FontAwesomeIcon icon={['fas', 'arrow-right']} size="lg"/>
                                </span>
                                
                                </Link>
                            </div> 
                        </Row>

                    </section>
                    
            </Container>
        </>
    )
}