import { About } from "../componants/About/About";
import { Demo } from "../componants/Demo/Demo";
import { Features } from "../componants/Features/Features";
import { Home } from "../componants/Home/Home";
import { Pricing } from "../componants/Pricing/Pricing";
import { Blog } from "../componants/Blog/Blog";
import { BuyNow } from "../componants/BuyNow/BuyNow";
import { Contact } from "../componants/Contact/Contact";
import { FrontEndFeatures } from "../componants/FrontEndFeatures/FrontEndFeatures";
import { AdminFeatures } from "../componants/AdminFeatures/AdminFeatures";
import { Buyer } from "../componants/Buyer/Buyer";
import { FAQs } from "../componants/FAQs/FAQs";
import { FacebookCanHelpSuccessEvent } from "../componants/Blog/BlogButton/FacebookCanHelpSuccessEvent";
import { MajorRoleSocialMedia } from "../componants/Blog/BlogButton/MajorRoleSocialMedia";
import { StartupIdeas } from "../componants/Blog/BlogButton/StartupIdeas";
import { ServerRequirements } from "../componants/Demo/ServerRequirements";
import { Support } from "../componants/Support/Support";
import {SaleQuestion } from "../componants/PreSalesQuestions/SaleQuestion";
import {DeliveryPolicy } from "../componants/DeliveryPolicy/DeliveryPolicy";
import {TermsAndCondition } from "../componants/TermsAndCondition/TermsAndCondition";
import {PrivacyPolicy } from "../componants/PrivacyPolicy/PrivacyPolicy";


const routes = [
  {
    path: "/",
    component: Home,
  },
  {
    path: "/about",
    component: About,
  },
  {
    path: "/features",
    component: Features,
  },
  {
    path: "/demo",
    component: Demo,
  },
  {
    path: "/pricing",
    component: Pricing,
  },
  {
    path: "/blog",
    component: Blog,
  },
  {
    path: "/buy-now",
    component: BuyNow,
  },
  {
    path: "/contact",
    component: Contact,
  },
  {
    path: "/front-end-features",
    component: FrontEndFeatures,
  },
  {
    path: "/admin-features",
    component: AdminFeatures,
  },
  {
    path: "/buyer",
    component: Buyer,
  },
  {
    path: "/faqs",
    component: FAQs,
  },
  {
    path: "/facebook-can-help-success-event",
    component: FacebookCanHelpSuccessEvent,
  },
  {
    path: "/major-role-social-media",
    component: MajorRoleSocialMedia,
  },
  {
    path: "/startup-ideas",
    component: StartupIdeas,
  },
  {
    path: "/server-requirements",
    component: ServerRequirements,
  },
  {
    path: "/support",
    component: Support,
  },
  {
    path: "/sale-question",
    component: SaleQuestion,
  },
  {
    path: "/delivery-policy",
    component: DeliveryPolicy,
  },
  {
    path: "/terms-and-condition",
    component: TermsAndCondition,
  },
  {
    path: "/privacy-policy",
    component: PrivacyPolicy,
  },
];

export { routes };
