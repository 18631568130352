import React, {useEffect} from 'react'
import { Container } from 'react-bootstrap';
import { Row, Col } from 'react-bootstrap';
import { Link } from "react-router-dom";
import "./DeliveryPolicy.scss";

export const DeliveryPolicy = () => {
    useEffect(() => {
        window.scrollTo({ top: 0, behavior: "smooth" });
      }, [])
    
    return (
<>
        <Container>
          <div className="policy-hero inner-hero-phone">
              <h1 className="vertical-center">Delivery Policy</h1>
          </div>  

          <Row className="align-items-center paragraph-wrpr-row padding-y-40 top-space">
          
          <Col xs={12} md={12} className="order-md-1 order-sm-2">
          <div>
            <h3>
                Delivery policy for following package:
            </h3>
                <p>
                This policy for above packages will be delivery max 48 hours. If you will not receive in more than 48 hours, please contact on support team. You will receive product in your emails that use during product purchase (PLEASE CHECK SPAM FOLDER), delay in product delivery can be reason of anti-fraud purpose. For any other reason we will contact you and let you know reason in detail. All packages are delivery within 48 hours because it is already ready and available to use.
                </p>      
            </div>
            </Col>
            <Col xs={12} md={12} className="order-md-2 order-sm-1">
            <div className='bulletlisting li-wrapper'>
            <ul>
                <li>We will not change one package to other package in future.</li>
                <li>We will not provide one package feature to other package feature on request.</li>
                <li>We will not provide extra domain license for development purpose or other purpose.</li>
            </ul>
            </div>
                  
            </Col>
          </Row>

          <Row className="align-items-center paragraph-wrpr-row padding-y-40">
          
          <Col xs={12} md={12} className="order-md-1 order-sm-2">
          <div>
            <h3>
            Delivery policy for extended package:
            </h3>
                <p>
                   <strong>Delivery time: Min 50 business days (Monday to Friday)</strong>
    In extended package, one person from <a href='./about' target="_blank"> Ticketingsoftwares.com</a> will contact you in 48 after order and will work with you for design, development and testing. That includes payment gateway configuration and other development work.
                </p> 
                <p>
                    Extended package is totally from scratch. We will do detail discussion for design and provide unique design with promise that we will never sell same design to someone in future. During designing phase we will design all pages including home to contact us page and take each page approve for next process. We will html of all the page and start programming.
                </p>     
            </div>   
            </Col>
            <Col xs={12} md={12} className="order-md-2 order-sm-1">
            <div className='bulletlisting li-wrapper'>
            <ul>
                <li>Design revision allow 3 times on each page.</li>
                <li>HTML revision allow 3 times on each page.
                Unlimited support will provide for programming bug during development of project. We will install all code to your server and execute final testing also provide code for back up purpose.
                </li>
                <li>We will not provide basic package or any other package in extended package for development.</li>
                <li>We will not provide any ticket support during project development. Ticket support available after uploading project to client server. Support period is mention in package details list.</li>
                <li>Many times client also hire local developer to work on project and same time order extended package to complete work in this case we will not provide any support to your developer for any technical question and doubt.</li>
                <li>We will design, decide and execute plan of development and project according to our requirement.</li>
            </ul>
            </div>
            </Col>
          </Row>

          <Row className="align-items-center paragraph-wrpr-row padding-y-40">
          
          <Col xs={12} md={12} className="order-md-1 order-sm-2 li-wrapper">
          <div>
            <h3>
            Custom programming work:
            </h3>
                <p>
                If you have ordered something custom made, we will inform you about the delivery date. The delivery dates are usually "expected" and not guaranteed unless we have specifically agreed for a dead line prior to making a purchase and payment.
                </p>
                <p>
                If it's more than 24 hours you have paid and we have not been in touch, there might 
                be an error with our internal system and we might have received your payment but not the 
                order so please get in touch via<button className="anchore-tag-prop" onClick={() => window.location = 'mailto:yourmail@domain.com'}> rockersinfo@gmail.com</button>
                </p>      
            </div>
            </Col>
          </Row>
        </Container>
</>   
    )
}
