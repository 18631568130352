import React,{useState} from 'react'
import { ConvertImage } from '../ConvertedImage/ConvertImage'

/**
 * 
 * @param {String} src
 * @param {String} className
 * @param {String} alt
 * @param {Number} height
 * @param {Number} width 
 * @returns 
 */
export const WebImage = ({src,className,alt,height,width}) => {
    const [converterd, setConverted] =useState(undefined);
    const handleConvertedImage= (url) => {
    const elem = document.createElement('canvas');

        if (!!(elem.getContext && elem.getContext('2d'))) {
            // was able or not to get WebP representation
            setConverted(url)
            return elem.toDataURL('image/webp').indexOf('data:image/webp') === 0;
        }
        else {
            // very old browser like IE 8, canvas not supported
            setConverted(src)
            return false;
        }
    }
    
    return (
        <div>
            {converterd ? <img className={className} src={converterd} alt={alt} height={height} width={width}/>  : <ConvertImage src={src} className={className} onConversion={handleConvertedImage} format="webp" />}
        </div>
    )
}
